<template>
    <div class="container flex min-h-screen mt-6 mb-6 flex-col justify-items-center mx-auto px-4 sm:px-6 lg:px-8">
        <div>
            <a href="https://complaia.com/"><img class="h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia.com - complaint system for buisness" /></a>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              Create a new account
            </h2>
            <p class="mt-2 text-sm text-gray-600">
              Have account already?
              {{ ' ' }}
              <router-link to="/" class="font-medium text-blue-500 hover:text-blue-400 transition-all">Log in here.</router-link>
            </p>
        </div>

        <div class="mt-6">
          <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Account details</h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Create your login credentials.
                  </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" id="form-1">
                    <div class="grid grid-cols-6 gap-6">

                      <div class="col-span-6 sm:col-span-4">
                        <label for="email-address" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.email === true}">Email address*</label>
                        <input type="text" name="email-address" v-model="registerForm.email" id="email-address" autocomplete="email" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.email === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.email">Your email is incorrect. (Example: joedoe@gmail.com)</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="first-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.password === true}">Password*</label>
                        <input type="password" name="pass" id="pass" v-model="password" autocomplete="new-password" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.password === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.password">Your password must be at least 6 characters.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.repeatPass === true}">Repeat password*</label>
                        <input type="password" name="repeatpass" id="repeatpass" v-model="repeatPassword" autocomplete="new-password" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.repeatPass === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.repeatPass">Both password fields must be the same.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="first-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.name === true}">First name*</label>
                        <input type="text" name="first-name" id="first-name" v-model="registerForm.name" autocomplete="given-name" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.name === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.name">Field cannot be empty.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.surname === true}">Last name*</label>
                        <input type="text" name="last-name" id="last-name" v-model="registerForm.surname" autocomplete="family-name" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.surname === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.surname">Field cannot be empty.</p>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone number</label>
                        <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' name="phone-number" id="phone-number" v-model="registerForm.phoneNumber" autocomplete="tel" class="mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.phone">Your phone number is incorrect or have no country code. (Example: +48123456789) </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
          <div class="bg-white mt-6 shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div class="md:grid md:grid-cols-3 md:gap-6" id="form-2">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Company details</h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Basic information about organization.
                  </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST">
                    <div class="grid grid-cols-6 gap-6">
                      
                      <div class="col-span-6 sm:col-span-4">
                        <label for="company-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.company === true}">Company name*</label>
                        <input type="text" name="company-name" id="company-name" v-model="registerForm.company.name" autocomplete="organization" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.company === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.company">Field cannot be empty.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-4">
                        <label for="company-tax" class="block text-sm font-medium text-gray-700">Tax Identification Number</label>
                        <input type="text" name="company-tax" id="company-tax" v-model="registerForm.company.taxIdNum" autocomplete="organization" class="mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                        <label for="street-address" class="block text-sm font-medium text-gray-700">Address</label>
                        <input type="text" name="street-address" id="street-address" v-model="registerForm.company.address" autocomplete="street-address" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm">
                      </div>

                      <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                        <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                        <input type="text" name="city" id="city" autocomplete="address-level2" v-model="registerForm.company.city" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm ">
                      </div>


                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
                        <input type="text" name="region" id="region" autocomplete="address-level1" v-model="registerForm.company.state" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm">
                      </div>

      

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="postal-code" class="block text-sm font-medium text-gray-700">ZIP / Postal code</label>
                        <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code" v-model="registerForm.company.postalCode" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                        <input type="text" name="country" id="country" autocomplete="country-name" v-model="registerForm.company.country" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm">
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
        <div>
          <div class="mt-6 relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
            </div>
          </div>
        </div>
        <div class="flex justify-center px-2 sm:justify-end sm:px-0 mt-6">
          <div class="mt-1 mr-2">
            <input type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'hover:border-gray-500': !acceptTerms}" v-model="acceptTerms" />
          </div>
            <p class="mt-2 text-sm text-gray-600 font-semibold">
              I have read and agree to the
              {{ ' ' }}
              <router-link to="/privacy/privacy-policy" target="_blank" class="font-medium text-blue-400  hover:text-blue-500 transition-all">terms of use & privacy policy.</router-link>

            </p>
        </div>
        <div class="flex justify-center px-2 sm:justify-end sm:px-0 mt-2 text-xs text-gray-500">
          <p>
             You will be asked to verify your credit card in the next step. You will not be charged until your free trial ends. You can resign at any moment.
          </p>
        </div>
        <div class="flex justify-end mt-6 mb-3 text-red-600 text-sm" v-if="errors.postClickError">
          {{postErrorInfo}}
        </div>
        <div class="flex justify-end mt-6 mb-6">
          <button id="createAccount" :disabled="!acceptTerms" type="submit" @click.prevent="validateForm" :class="['ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
            Create an account and start 7 days trial
          </button>
        </div>
        <Loader v-if="showLoader" :header="loaderTexts.header" :subheader="loaderTexts.subheader">

        </Loader>

       


    </div>
</template>

<script>
// Import Stripe.js
import {loadStripe} from '@stripe/stripe-js';
// Import Axios
import axios from 'axios';
// Import loader
import Loader from '@/components/Loader.vue'
// Import Firebase
import {db} from '@/firebase/gfbconf.js';

export default {
  name: "Register",
  data: function () {
    return {
      loaderTexts: {
        header: "Loading..",
        subheader: "This may take a few seconds, please Do not close this page."
      },
      acceptTerms: false,
      showLoader: false,
      errors: {
        password: false,
        repeatPass: false,
        email: false,
        name: false,
        surname: false,
        phone: false,
        company: false,
        postClickError: false
      },
      postErrorInfo: "",
      password: "",
      repeatPassword: "",
      registerForm: {
        name: "",
        surname: "",
        phoneNumber: "",
        email: "",
        company: {
          name: "",
          taxIdNum: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
          country: ""
        }
      }
    }
  },
  components: {
    Loader
  },
  mounted()
  {
      if(window.dataLayer !== undefined)
      {
        if(window.dataLayer !== null)
        {

          window.dataLayer.push({
            event: "visitedAppRegisterPage",
            lang: window.navigator.userLanguage || window.navigator.language,
            system: window.navigator.userAgent,
            plan: this.$route.query.plan,
            knownLanguages: window.navigator.languages,
            comingFrom: window.document.referrer
          });


          setTimeout(() => {
            window.dataLayer.push({
              event: "10secondOnRegisterForm",
              reached: true,
              info: "10 seconds"
            });
          },10000);

          setTimeout(() => {
            window.dataLayer.push({
              event: "30secondOnRegisterForm",
              reached: true,
              info: "30 seconds"
            });
          },30000);
        }
      }
  },
  methods: {
    scrollTo(name)
    {
      document.getElementById(name).scrollIntoView({
        behavior: 'smooth'
      });
    },
    async createSystem()
    {
      // Creates new admin user & system via api and sends to Stripe checkout.
      // Init Stripe
      this.showLoader = true;
      this.loaderTexts.header = "Loading.."
      this.loaderTexts.subheader = "This may take a few seconds, please Do not close this page.."
      await loadStripe('pk_live_51KWlnFF0lxeRGWNEk81sePwYq3EfdeqMuscoVFQE7Dkai3TJKxiSGRAOGAFbxkJHMKCsBOZGopXuUPtbH9BFQRSN00W3KokPUx');
      // Create system and user

      this.loaderTexts.header = "Creating your account.."
      this.loaderTexts.subheader = "Preparing Complaia System.. please Do not close this page."
      // Conversion Google Ads
      if(window.dataLayer !== undefined)
      {
        if(window.dataLayer !== null)
        {
            window.dataLayer.push({
              event: `${this.$route.query.plan}`,
              subscribed: true,
            });          
        }
      }
      
      const creationResult = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/setup/initialize/newSystemAndAdmin', {
        password: this.password,
        registerForm: this.registerForm,
        selectedPlan: this.$route.query.plan
      });


      if(creationResult.data.status === 'success')
      {
        await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/sendEmail', {
          attendantEmail: this.registerForm.email,
          to: this.registerForm.email,
          messageType: "register",
          payload: { fullName: this.registerForm.name + ' ' + this.registerForm.surname }
        });
        this.loaderTexts.header = "Let's go to Stripe page.."
        this.loaderTexts.subheader = "So close now.. Redirecting.."
        // Hide previously created errors. 
        this.postErrorInfo = "";
        this.errors.postClickError = false;
        // Log into current user account;
        await this.$store.dispatch("login", {
            email: this.registerForm.email,
            password: this.password,
            firstLogin: true
        });
        // Create link to payment
        const docRef = await db
          .collection('UsersData')
          .doc(this.$store.state.user.uid)
          .collection('checkout_sessions')
          .add({
            price: this.$route.query.plan,
            success_url: 'https://app.complaia.com/dashboard/feed',
            cancel_url: 'https://app.complaia.com/?subscriptionFailed=true',
            trial_from_plan: true,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      }
      else
      {
        // Show error
        this.postErrorInfo = creationResult.data.info;
        this.errors.postClickError = true;
        this.loaderTexts.header = "Ups going back.."
        this.loaderTexts.subheader = "Cannot create new account. More info after we hide this popup."

        setTimeout(()=>
        {
          this.showLoader = false;
        },1000);
      }

    },
    validateForm()
    {
      // Check if email is OK
      if( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.registerForm.email))
      {
        this.errors.email = false;
        // Check if pass is OK
        if(this.password.length >= 6)
        {
          this.errors.password = false;
          // Check if repeat pass is the same as pass.
          if(this.password === this.repeatPassword)
          {
            this.errors.repeatPass = false;
            // Check if name is not empty
            if(this.registerForm.name.length>0)
            {
              this.errors.name = false;
              // Check if surname is not empty
              if(this.registerForm.surname.length>0)
              {
                this.errors.surname = false;
                // Check company name
                if(this.registerForm.company.name.length>0)
                {
                  this.errors.company = false;
                  this.createSystem();
                }
                else
                {
                  this.errors.company = true;
                  this.scrollTo('form-2')
                  return 0;
                }
              }
              else
              {
                this.errors.surname = true;
                this.scrollTo('form-1')
                return 0;
              }
            }
            else
            {
              this.errors.name = true;
              this.scrollTo('form-1')
              return 0;
            }
          }
          else
          {
            this.errors.repeatPass = true;
            this.scrollTo('form-1')
            return 0;
          }
        }
        else
        {
          this.errors.password = true;
          this.scrollTo('form-1')
          return 0;
        }
      }
      else
      {
        this.errors.email = true;
        this.scrollTo('form-1')
        return 0;
      }
    },
    async signupUser() {
      try {
        await this.$store.dispatch("signup", {
          email: this.email,
          //password: this.password,
        });
        this.$router.push("/dashboard");
      } catch (err) {
        console.log(err.message);
      }
    }
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#createAccount:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#createAccount:enabled:hover {
  background-color: #3b82f6;
}

</style>
