<template>
    <div id="advancedFeed" class="mt-2">
        <AllEvents />
    </div>
</template>
<script>
import AllEvents from '@/components/AllEvents';

    export default {
        name: "AdvancedFeed",
        components: { AllEvents },
        data: function () {
            return {
                
            }
        },
        methods: {

        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
            
        }
    };
</script>