<template>
<p class="mt-1 mb-4 text-sm text-gray-500 select-none">Adjust online complaint form to your needs.</p>
    <!-- Fields manager -->
    <div v-if="!loading" class="fields-manager select-none">
        <transition name="fade">
            <Notification></Notification>
        </transition>
        <div class=" py-2 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-6" >
            <div class="lg:col-span-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">General</h3>
                <p class="mt-1 text-sm text-gray-500">Set title and copy your unique link.</p>
            </div>
            <!-- Portal link -->
            <div class="flex flex-col h-auto  lg:col-span-6">
                <label class="text-sm font-medium text-gray-900"> Unique link </label>
                <div class="flex">
                    <div class="select-none inline-flex w-full items-center px-3 py-2 mt-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 lg:text-sm overflow-hidden whitespace-nowrap pointer-events-none	"> <p>{{ portalLink }}</p> </div>
                    <button @click.prevent="copy" class=" inline-flex px-3 mt-1 justify-center items-center border border-l-0 border-transparent shadow-sm text-sm font-medium rounded-r-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Copy</button>
                </div>
            </div>

            <!-- htmlMeta input -->
            <div class="lg:col-span-6">
                <label for="htmlMeta" class="block text-sm font-medium text-gray-700"> Page title </label>
                <div class="mt-1">
                    <input v-model="htmlMeta.title" type="text" id="htmlMeta" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md border-gray-300': true }" />
                </div>
            </div>

            <!-- Appearance settings -->
            <div v-if="this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-span-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Appearance</h3>
                <p class="mt-1 text-sm text-gray-500">Change the design of the form.</p>
            </div>

            <!-- Drag and drop image -->
            <div class="lg:col-span-6">
                <label for="linkAttached" class="block text-sm font-medium text-gray-700"> Logo image </label>
                <div class="mt-1">
                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40" v-show="imgPreviewSrc.length>0">
                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                    </div>
                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                        <button type="button" @click.prevent="clearImg" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Clear photo</button>
                    </div>
                    
                    
                    <div class="transition ease-in duration-200 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0">
                        <div class="space-y-1 text-center">
                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                            <p class="pl-1 pr-1">Click on the box to</p>
                            <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
                            <span> Upload the file</span>
                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                            </label>
                        </div>
                        <p class="text-xs text-gray-500">.PNG, .JPG, .SVG up to 2 mb</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Link attached to image type -->
            <div v-if="this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-span-6" >
                <label for="returnLink" class="block text-sm font-medium text-gray-700"> Return link <span class="text-sm opacity-80">(Click on logo)</span> </label>
                <div class="mt-1">
                    <input placeholder="Link example: https://complaia.com/" v-model="logo.returnLink" type="text" id="returnLink" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md border-gray-300': true }" />
                </div>
            </div>
            <!-- Background type -->
            <div v-if="this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-span-6" >
              <Listbox as="div" v-model="appearance.backgroundType">
                <ListboxLabel class="block text-sm font-medium text-gray-700"> Background</ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                    <span class="block truncate">{{ appearance.backgroundType.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="type in backgroundType"  :key="type.id" :value="type" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9 ']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ type.name }}
                        </span>   
                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
          </div>

          <!-- Pick first color type -->
            <div v-if="(appearance.backgroundType.name === 'Solid' || appearance.backgroundType.name === 'Gradient') && this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-start-1 lg:col-span-3" >
              <Listbox as="div" v-model="appearance.background.firstColorType">
                <ListboxLabel class="block text-sm font-medium text-gray-700"> <span v-if="appearance.backgroundType.name === 'Gradient'">First background color</span><span v-if="appearance.backgroundType.name === 'Solid'">Background color</span> </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                    <span class="block truncate">{{ appearance.background.firstColorType }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="(colorType, index) in colorsType" :key="index" :value="colorType" v-slot="{ active, selected }">
                        <li :class="[appearance.backgroundType.name === 'Solid' ? active ? `text-white bg-${colorType.toLowerCase()}-${appearance.background.firstColorIntense}` : 'text-gray-900' : appearance.backgroundType.name === 'Gradient' ? active ? `text-white bg-gradient-to-r from-${colorType.toLowerCase()}-${appearance.background.firstColorIntense} to-${appearance.background.secondColorType.toLowerCase()}-${appearance.background.secondColorIntense}` : 'text-gray-900' : '', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ colorType }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
          </div>

          <!-- Pick first color intense -->
          <div v-if="(appearance.backgroundType.name === 'Solid' || appearance.backgroundType.name === 'Gradient') && this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-span-3" >
              <Listbox as="div" v-model="appearance.background.firstColorIntense">
                <ListboxLabel class="block text-sm font-medium text-gray-700"> Intensity </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                    <span class="block truncate">{{ appearance.background.firstColorIntense }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="(colorIntense, index) in colorsIntense" :key="index" :value="colorIntense" v-slot="{ active, selected }">
                        <li :class="[appearance.backgroundType.name === 'Solid' ? active ? `text-white bg-${appearance.background.firstColorType.toLowerCase()}-${colorIntense}` : 'text-gray-900' : appearance.backgroundType.name === 'Gradient' ? active ? `text-white bg-gradient-to-r from-${appearance.background.firstColorType.toLowerCase()}-${colorIntense} to-${appearance.background.secondColorType.toLowerCase()}-${appearance.background.secondColorIntense}` : 'text-gray-900' : '', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ colorIntense }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
          </div>
            <!-- Pick second color type -->
            <div v-if="appearance.backgroundType.name === 'Gradient' && this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-start-1 lg:col-span-3" >
            <Listbox as="div" v-model="appearance.background.secondColorType">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Second background color </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class=" bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                    <span v-if="appearance.background.secondColorType !== ''" class="block truncate">{{ appearance.background.secondColorType }}</span>
                    <span v-if="appearance.background.secondColorType === ''" class="block truncate text-gray-400">Select second color</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="(colorType, index) in colorsType" :key="index" :value="colorType" v-slot="{ active, selected }">
                        <li :class="[active ? `text-white bg-gradient-to-r from-${appearance.background.firstColorType.toLowerCase()}-${appearance.background.firstColorIntense} to-${colorType.toLowerCase()}-${appearance.background.secondColorIntense}` : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ colorType }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
        </div>

            <!-- Pick second color intense -->
            <div v-if="appearance.backgroundType.name === 'Gradient' && this.$store.state.userData.permissions.customerPortalStyling" class="lg:col-span-3" >
                <Listbox as="div" v-model="appearance.background.secondColorIntense">
                    <ListboxLabel class="block text-sm font-medium text-gray-700"> Intensity </ListboxLabel>
                    <div class="mt-1 relative">
                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                        <span v-if="appearance.background.secondColorIntense !== ''" class="block truncate">{{ appearance.background.secondColorIntense }}</span>
                        <span v-if="appearance.background.secondColorIntense === ''" class="block truncate text-gray-400">Select color intense</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                        <ListboxOption as="template" v-for="(colorIntense, index) in colorsIntense" :key="index" :value="colorIntense" v-slot="{ active, selected }">
                            <li :class="[active ? `text-white bg-gradient-to-r from-${appearance.background.firstColorType.toLowerCase()}-${appearance.background.firstColorIntense} to-${appearance.background.secondColorType.toLowerCase()}-${colorIntense}` : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ colorIntense }}
                            </span>

                            <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
            </div>

            <!-- Pick theme color -->
            <div class="lg:col-start-1 lg:col-span-6" v-if="this.$store.state.userData.permissions.customerPortalStyling">
            <Listbox as="div" v-model="appearance.themeColor">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Theme color </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                    <span class="block truncate">{{ appearance.themeColor }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="(colorType, index) in colorsType" :key="index" :value="colorType" v-slot="{ active, selected }">
                        <li :class="[active ? `text-white bg-${colorType.toLowerCase()}-500` : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ colorType }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
        </div>
        </div>
        
        <!-- Step 1 -->
        <div class="py-2 mt-8" >
            <div class="flex w-full justify-between items-center">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Step 1</h3>
                <div class="hidden flex-wrap text-sm font-medium mb-2 text-gray-900 lg:mt-4 lg:justify-end lg:flex" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
            </div>
            <!-- <p class="mt-1 text-sm text-gray-500"></p> -->
            <div class="relative mt-4 lg:mt-1">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t-2 border-gray-300 opacity-50" />
                </div>
            </div>
            <!-- Expectation field -->
            <SwitchGroup as="div" id="expectation-field" class="flex flex-wrap lg:flex-nowrap items-center mt-8 lg:mt-5 pb-2 lg:pb-3">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                        Expectation field
                    </SwitchLabel>
                    <span :class="{'transition ease-in-out duration-200 text-xs text-purple-500 w-full text-left py-1': true}"><span @click.prevent="showExpectationsFunction"  :class="{'transition-all duration-200  hover:text-purple-700 cursor-pointer': true, 'bg-purple-200 text-purple-700 border border-purple-400 rounded-md px-1.5 py-0.5': showWhereChange}">Choose options</span></span>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.product.expectationInput)" v-model="fields.product.expectationInput.isShowed" name="terms" value="tost" :class="[fields.product.expectationInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.expectationInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch class="ml-8" :disabled="!fields.product.expectationInput.isShowed" :class="['bg-gray-200', 'opacity-30  cursor-not-allowed', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200']">
                    <span aria-hidden="true" :class="['translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>
            <!-- Product name field -->
            <!-- <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Product name field</SwitchLabel>
                    <SwitchDescription as="span" class="text-sm text-gray-500">Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.</SwitchDescription>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 mt-5" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3">
                <Switch :checked="checkIsShowed(fields.product.productNameInput)" v-model="fields.product.productNameInput.isShowed" :class="[fields.product.productNameInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.productNameInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.product.productNameInput.isRequired" class="ml-8" :disabled="!fields.product.productNameInput.isShowed" :class="[fields.product.productNameInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.product.productNameInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.product.productNameInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div> -->

            <!-- Problem description field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-6 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Problem description field</SwitchLabel>
                    
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.product.descriptionInput)" v-model="fields.product.descriptionInput.isShowed" :class="[fields.product.descriptionInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.descriptionInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.product.descriptionInput.isRequired" class="ml-8" :disabled="!fields.product.descriptionInput.isShowed" :class="[fields.product.descriptionInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.product.descriptionInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.product.descriptionInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- Invoice number / Order number field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-6  pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Identification field</SwitchLabel>
                    <span class="text-sm w-full font-medium text-left py-1">
                        <span class=" text-gray-900">Type: </span>
                        <span @click.prevent="changeFieldName('Invoice number')" :class="[fields.product.invoiceNumberInput.fieldName === 'Invoice number' ? 'text-purple-500 hover:text-purple-700' : 'text-gray-400 hover:text-gray-600']" class="cursor-pointer">Invoice number </span>
                        <span class=" text-gray-400">/</span>
                        <span @click.prevent="changeFieldName('Order number')" :class="[fields.product.invoiceNumberInput.fieldName === 'Order number' ? 'text-purple-500 hover:text-purple-700' : 'text-gray-400 hover:text-gray-600']" class="cursor-pointer"> Order number</span>
                    </span>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.product.invoiceNumberInput)" v-model="fields.product.invoiceNumberInput.isShowed" :class="[fields.product.invoiceNumberInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.invoiceNumberInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.product.invoiceNumberInput.isRequired" class="ml-8" :disabled="!fields.product.invoiceNumberInput.isShowed" :class="[fields.product.invoiceNumberInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.product.invoiceNumberInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.product.invoiceNumberInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- Image field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-6 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Image uploader</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.product.imgInput)" v-model="fields.product.imgInput.isShowed" :class="[fields.product.imgInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.imgInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.product.imgInput.isRequired" class="ml-8" :disabled="!fields.product.imgInput.isShowed" :class="[fields.product.imgInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.product.imgInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.product.imgInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- PDF field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-4 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900 flex items-center"  passive>
                        <span :class="[userSubscription.pdfUpload === 'false' ? 'opacity-30 cursor-not-allowed' : 'opacity-100']">PDF attachment</span> 
                        <div @click="goToAccount()" v-if="userSubscription.pdfUpload === 'false'" :class="['transition ease-in-out duration-300 p-1px bg-gradient-to-r from-purple-500 to-cyan-400 rounded-lg opacity-100 ml-3', this.$store.state.userData.systemCreator === true ? 'cursor-pointer hover:opacity-80' : 'pointer-events-none']">
                            <div class="bg-purple-50 rounded-lg px-1.5 flex justify-center items-center">
                            <span class="opacity-100 text-xxs-1 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-cyan-500">PRO</span>
                            </div>
                        </div>
                        </SwitchLabel>
                    
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " :class="[userSubscription.pdfUpload === 'false' ? 'opacity-30 cursor-not-allowed' : 'opacity-100']" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.product.pdfInput)" :disabled="userSubscription.pdfUpload === 'false'" v-model="fields.product.pdfInput.isShowed" :class="[userSubscription.pdfUpload === 'false' ? 'opacity-30  cursor-not-allowed' : 'opacity-100', fields.product.pdfInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.product.pdfInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.product.pdfInput.isRequired" class="ml-8" :disabled="!fields.product.pdfInput.isShowed || userSubscription.pdfUpload === 'false'"  :class="[fields.product.pdfInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.product.pdfInput.isShowed || userSubscription.pdfUpload === 'false' ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.product.pdfInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>
        </div>

        <!-- Step 2 -->
        <div class="py-2 mt-8" >
            <!-- <h3 class="text-lg leading-6 font-medium text-gray-900">Step 2</h3> -->
            <div class="flex w-full justify-between items-center">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Step 2</h3>
                <div class="hidden flex-wrap text-sm font-medium mb-2 text-gray-900 lg:mt-4 lg:justify-end lg:flex" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
            </div>
            <!-- <p class="mt-1 text-sm text-gray-500">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> -->
            <div class="relative mt-4 lg:mt-1">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t-2 border-gray-300 opacity-50" />
                </div>
            </div>
            <!-- Name field
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-8 lg:mt-5 pb-2 lg:pb-3">
                <span class="flex flex-col w-auto whitespace-nowrap lg:my-3">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900 min-w-auto" passive>Name field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap lg:flex-nowrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.nameInput)" v-model="fields.credentials.nameInput.isShowed" name="terms" value="tost" :class="[fields.credentials.nameInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.nameInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.nameInput.isRequired" class="ml-8" :disabled="!fields.credentials.nameInput.isShowed" :class="[fields.credentials.nameInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.nameInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.nameInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div> -->

            <!-- Phone field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-7 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Phone field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.phoneInput)" v-model="fields.credentials.phoneInput.isShowed" :class="[fields.credentials.phoneInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.phoneInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.phoneInput.isRequired" class="ml-8" :disabled="!fields.credentials.phoneInput.isShowed" :class="[fields.credentials.phoneInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.phoneInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.phoneInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- Country field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Country field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.countryInput)" v-model="fields.credentials.countryInput.isShowed" :class="[fields.credentials.countryInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.countryInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.countryInput.isRequired" class="ml-8" :disabled="!fields.credentials.countryInput.isShowed" :class="[fields.credentials.countryInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.countryInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.countryInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- City field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>City field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.cityInput)" v-model="fields.credentials.cityInput.isShowed" :class="[fields.credentials.cityInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.cityInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.cityInput.isRequired" class="ml-8" :disabled="!fields.credentials.cityInput.isShowed" :class="[fields.credentials.cityInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.cityInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.cityInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- Street address field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Street address field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.streetAddressInput)" v-model="fields.credentials.streetAddressInput.isShowed" :class="[fields.credentials.streetAddressInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.streetAddressInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.streetAddressInput.isRequired" class="ml-8" :disabled="!fields.credentials.streetAddressInput.isShowed" :class="[fields.credentials.streetAddressInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.streetAddressInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.streetAddressInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- ZIP / Postal code field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>ZIP / Postal code field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.credentials.postCodeInput)" v-model="fields.credentials.postCodeInput.isShowed" :class="[fields.credentials.postCodeInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.credentials.postCodeInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.credentials.postCodeInput.isRequired" class="ml-8" :disabled="!fields.credentials.postCodeInput.isShowed" :class="[fields.credentials.postCodeInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.credentials.postCodeInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.credentials.postCodeInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-200 opacity-70" />
                </div>
            </div>
        </div>

        <!-- Step 3 -->
        <div class="py-2 mt-8" >
            <!-- <h3 class="text-lg leading-6 font-medium text-gray-900">Step 3</h3> -->
            <div class="flex w-full justify-between items-center">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Step 3</h3>
                <div class="hidden flex-wrap text-sm font-medium mb-2 text-gray-900 lg:mt-4 lg:justify-end lg:flex" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
            </div>
            <div class="relative mt-4 lg:mt-1">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t-2 border-gray-300 opacity-50" />
                </div>
            </div>
            <!-- Country -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-8 lg:mt-5 pb-2 lg:pb-3">
                <span class="flex flex-col w-auto whitespace-nowrap lg:my-3">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900 min-w-auto" passive>Country field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap lg:flex-nowrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.deliveryChargeback.countryInput)" v-model="fields.deliveryChargeback.countryInput.isShowed" name="terms" value="tost" :class="[fields.deliveryChargeback.countryInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.countryInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.deliveryChargeback.countryInput.isRequired" class="ml-8" :disabled="!fields.deliveryChargeback.countryInput.isShowed" :class="[fields.deliveryChargeback.countryInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.deliveryChargeback.countryInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.countryInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- City -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-6 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>City field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.deliveryChargeback.cityInput)" v-model="fields.deliveryChargeback.cityInput.isShowed" :class="[fields.deliveryChargeback.cityInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.cityInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.deliveryChargeback.cityInput.isRequired" class="ml-8" :disabled="!fields.deliveryChargeback.cityInput.isShowed" :class="[fields.deliveryChargeback.cityInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.deliveryChargeback.cityInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.cityInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- Street address -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Street address field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.deliveryChargeback.streetAddressInput)" v-model="fields.deliveryChargeback.streetAddressInput.isShowed" :class="[fields.deliveryChargeback.streetAddressInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.streetAddressInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.deliveryChargeback.streetAddressInput.isRequired" class="ml-8" :disabled="!fields.deliveryChargeback.streetAddressInput.isShowed" :class="[fields.deliveryChargeback.streetAddressInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.deliveryChargeback.streetAddressInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.streetAddressInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>

            <!-- ZIP / Postal code field -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-9 lg:mb-3 pb-2">
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>ZIP / Postal code field</SwitchLabel>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900 " >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.deliveryChargeback.postCodeInput)" v-model="fields.deliveryChargeback.postCodeInput.isShowed" :class="[fields.deliveryChargeback.postCodeInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.postCodeInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.deliveryChargeback.postCodeInput.isRequired" class="ml-8" :disabled="!fields.deliveryChargeback.postCodeInput.isShowed" :class="[fields.deliveryChargeback.postCodeInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.deliveryChargeback.postCodeInput.isShowed ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.postCodeInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>
            <!-- Bank account number -->
            <SwitchGroup as="div" class="flex flex-wrap lg:flex-nowrap items-center mt-4 lg:mt-6 pb-2" >
                <!-- <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Bank account number field</SwitchLabel>
                </span> -->
                <span class="flex flex-col w-auto whitespace-nowrap">
                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>Bank account number field</SwitchLabel>
                    <span class="text-sm w-full font-medium text-left py-1">
                        <div v-if="checkIsCheckout" @click.prevent="goTo()" class="transition ease-in-out duration-200 max-w-max text-xs text-yellow-500 hover:text-yellow-600 cursor-pointer flex bg-yellow-100 hover:bg-yellow-200 p-1 rounded-lg">
                            <svg aria-hidden="true" class="w-4 h-4 text-yellow-500 dark:text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <span style="margin-left: 0.15rem;">To unlock this option, you need to change available expectations in step 1.</span>
                        </div>
                    </span>
                </span>
                <span class="mt-4 lg:mt-0 lg:ml-36 flex-wrap flex items-center lg:block w-full">
                <div :class="[checkIsCheckout ? 'opacity-30 cursor-not-allowed' : 'opacity-100']" class="w-full flex flex-wrap text-sm font-medium lg:hidden mb-2 text-gray-900" >
                    <p class="flex-initial" style="width: 4.7rem">Shown</p>
                    <p class="flex-initial" style="width: ">Required</p>
                </div>
                <div class="text-right lg:mr-3 lg:flex lg:justify-end lg:items-center">
                <Switch :checked="checkIsShowed(fields.deliveryChargeback.bankAccountNumberInput)" :disabled="checkIsCheckout" v-model="fields.deliveryChargeback.bankAccountNumberInput.isShowed" :class="[checkIsCheckout ? 'opacity-30  cursor-not-allowed' : 'opacity-100', fields.deliveryChargeback.bankAccountNumberInput.isShowed ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.bankAccountNumberInput.isShowed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <Switch v-model="fields.deliveryChargeback.bankAccountNumberInput.isRequired" class="ml-8" :disabled="!fields.deliveryChargeback.bankAccountNumberInput.isShowed || checkIsCheckout" :class="[fields.deliveryChargeback.bankAccountNumberInput.isRequired ? 'bg-blue-600' : 'bg-gray-200', !fields.deliveryChargeback.bankAccountNumberInput.isShowed || checkIsCheckout ? 'opacity-30  cursor-not-allowed' : 'opacity-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                    <span aria-hidden="true" :class="[fields.deliveryChargeback.bankAccountNumberInput.isRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                </div>
                </span>
            </SwitchGroup>
                <!-- <div v-if="checkIsCheckout" @click.prevent="goTo()" class="transition ease-in-out duration-200 max-w-max text-xs mb-3 text-yellow-500 hover:text-yellow-600 cursor-pointer flex bg-yellow-100 hover:bg-yellow-200 p-1 rounded-lg">
                    <svg aria-hidden="true" class="w-4 h-4 text-yellow-500 dark:text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <span style="margin-left: 0.15rem;">If you want to unlock this option change expectations in step 1</span>
                </div> -->
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200 opacity-70" />
                    </div>
                </div>
            <div class="flex justify-end my-6">
                <!-- <button @click.prevent="clonePortalSettings()" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Clone</button> -->
                <button @click.prevent="saveChangesModal(true)" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Save changes</button>
            </div>
        </div>
        
        <!-- Choose expectation option modal -->
        <TransitionRoot as="template" :show="showExpectations">
            <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                    <div class="w-full">
                    <div class="mt-3 sm:mt-0 sm:ml-4 text-leftl">
                        <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Settings </DialogTitle>
                        <div class="mt-3">
                        <!-- Default expectation select -->
                         <div class="lg:col-span-6" >
                            <Listbox as="div" v-model="portalOptions.default">
                            <ListboxLabel class="block text-md font-medium text-gray-900"> Default expectation </ListboxLabel>
                            <div class="mt-1 relative">
                            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                <span  class="block truncate">{{ portalOptions.default.name }}</span>
                                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md  ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                <ListboxOption @click.prevent="checkDefault(index)" as="template" v-for="(option, index) in expectationValues"  :key="option.name" :value="option" v-slot="{ active, selected }">
                                    <li :class="[active || portalOptions.default.name === option.name  ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9 ']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                        {{ option.name }}
                                    </span>   
                                    <span v-if="selected || portalOptions.default.name === option.name" :class="[active || portalOptions.default.name === option.name ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    </li>
                                </ListboxOption>
                                </ListboxOptions>
                            </transition>
                            </div>
                        </Listbox>
                        </div>
                        <!-- Default expectation select -->
                        </div>
                        <div class="block text-md font-medium text-gray-900 mt-3"> Selected expectations </div>
                        <div class="relative mt-1 mb-4">
                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t-2 border-gray-300 opacity-50" />
                            </div>
                        </div>
                        <div v-for="(option, index) in expectationValues" :key="index" class="w-full">
                            <SwitchGroup as="div" class="flex flex-nowrap items-center mt-2 pb-2" >
                                <span class="flex flex-col w-auto whitespace-nowrap">
                                    <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>{{ option.name }}</SwitchLabel>
                                    <SwitchDescription as="span" class="text-sm text-gray-500">Bank field required: <span class="text-sm font-medium" :class="[option.isCheckout ? 'text-green-500' : 'text-red-500']">{{ option.isCheckout ? 'yes' : 'no'}}</span></SwitchDescription>
                                </span>
                                <span class="mt-4 flex-wrapitems-center block w-full">
                                <div class="text-right">
                                <Switch v-model="option.isSelected"  :disabled="option.name === portalOptions.default.name" :class="[option.name === portalOptions.default.name ? 'opacity-50  cursor-not-allowed' : 'opacity-100', option.isSelected ? 'bg-blue-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400']">
                                    <span aria-hidden="true" :class="[option.isSelected ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                                </Switch>
                                </div>
                                </span>
                            </SwitchGroup>
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div class="w-full border-t border-gray-200 opacity-70" />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button @click.prevent="saveExpectations()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto text-sm" >Save</button>
                    <button @click.prevent="showExpectationsFunction()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto text-sm" ref="cancelButtonRef">Cancel</button>
                    </div>
                </div>
                </TransitionChild>
            </div>
            </Dialog>
        </TransitionRoot>
        <!-- Choose expectation option modal -->
        
        <AlertModal v-if="saveChanges" :buttonColor="'purple'" :iconColor="'yellow'"  :modalData="modalData" @accept="savePortalSettings()" @closeModal="saveChangesModal(false)"></AlertModal>
    </div>
    <!-- End fields manager -->
</template>

<script>
    // import axios from 'axios';
    import moment from 'moment'
    import { db, storage } from "@/firebase/gfbconf.js"
    import Notification from '../components/Notification.vue';
    import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
    import useClipboard from 'vue-clipboard3'
    import AlertModal from '../components/AlertModal.vue'
    import { ref } from 'vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

    export default {
        name: "CustomerPortalManager",
        components: { Switch, SwitchDescription, SwitchGroup, SwitchLabel, Notification, AlertModal,
        Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, SelectorIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot},
        data()
        {
        return {
            fileLimitSize: 2097152,
            portalLink: "",
            loading: true,
            saveChanges: false,
            expectationValues: [],
            appearance: {},
            logo: {},
            userSubscription: "",
            portalOptions: {},
            backgroundType: [
                { id: 0, name: 'Solid' },
                { id: 1, name: 'Gradient' },
            ],
            showWhereChange: false,
            fields: {},
            htmlMeta: {},
            showExpectations: false,
            modalData: {
                headerText: "Update customer portal settings",
                bodyText: "Are you sure you want to update settings in your customer portal?",
                acceptButtonText: "Save changes"
            },
            imgPreviewSrc: '',
            colorsType: [
                'Gray',
                'Pink' ,
                'Rose',
                'Red',
                'Orange',
                'Yellow',
                'Lime',
                'Green',
                'Teal',
                'Cyan',
                'Blue',
                'Indigo',
                'Purple',
            ],
            colorsIntense: [
                '50',
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
                '900',
            ]
        }
        },
        created()
        {
            this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Loading your customer portal..",
                subheader: `Do not close this tab.`,
            });
            this.downloadPortalSettings()
            this.userSubscription = this.$store.state.userSubscription.items[0].price.product.metadata
        },
        watch: {
            checkIsCheckout(a) {
                if(a) {
                    this.fields.deliveryChargeback.bankAccountNumberInput.isShowed = true
                    this.fields.deliveryChargeback.bankAccountNumberInput.isRequired = true
                }
            }
        },
        methods:
        {
            filenameGenerator(length)
            {
                let result           = `${this.$store.state.userData.system.id}_prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            clearImg()
            {
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                            
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "File is more than 2MB",
                            subheader: `Image is too large.`,
                            success: false
                        });                    
                    }
                }
            },
            goToAccount(){
                // console.log(this.$store.state.userData.systemCreator)
                if(this.$store.state.userData.systemCreator === true){
                    // this.$router.push('/dashboard/profile?billing=true', '_blank')
                    window.open('/dashboard/profile?billing=true', '_blank');
                }
            },
            evaluate(string) {
              return eval(string)
            },
            changeFieldName(name) {
                this.fields.product.invoiceNumberInput.fieldName = name
            },
            goTo() {
                const elmntToView = document.getElementById(`expectation-field`);
                elmntToView.scrollIntoView({block: "center", behavior: "smooth"});
                this.showWhereChange = true
                setTimeout(() => {
                    this.showWhereChange = false
                }, 3000);
            },
            checkDefault(index) {
                this.expectationValues[index].isSelected = true
            },
            showExpectationsFunction() {
                this.showExpectations = !this.showExpectations
            },
            saveChangesModal(state) {
                if(!state) {
                    setTimeout(() => {
                        this.saveChanges = state
                    }, 300) 
                } else {
                    this.saveChanges = state
                }
            },
            async saveExpectations() {
                this.portalOptions.values = []
                let isCheckout = false
                // console.log(this.portalOptions);
                this.expectationValues.forEach(expectation => {
                    if(expectation.isSelected) {
                        if(expectation.isCheckout) {
                            // console.log(expectation);
                            isCheckout = true
                        }
                        this.portalOptions.values.push({
                            name: expectation.name,
                            isCheckout: expectation.isCheckout
                        })
                    }
                })
                // console.log(isCheckout);
                this.portalOptions.default = {
                    name: this.portalOptions.default.name,
                    isCheckout: this.portalOptions.default.isCheckout
                }
                try {
                    const portalSettingsRef = await this.$store.state.userData.system.ref
                    this.showExpectationsFunction()
                    if(!isCheckout) {
                        await portalSettingsRef.update({
                            'portal.settings.form.fields.product.expectationInput.options': this.portalOptions,
                        })
                    } 
                    else if (isCheckout) {
                        await portalSettingsRef.update({
                            'portal.settings.form.fields.product.expectationInput.options': this.portalOptions,
                            'portal.settings.form.fields.deliveryChargeback.bankAccountNumberInput.isRequired': true,
                            'portal.settings.form.fields.deliveryChargeback.bankAccountNumberInput.isShowed': true,
                        })
                    }

                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Successfully changed expectations!",
                        subheader: ``,
                        success: true
                    }); 
                } catch(error) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong.",
                        subheader: error,
                        success: false
                    }); 
                }
            },
            async copy() {
                try {
                   await useClipboard().toClipboard(this.portalLink);
                   this.$store.commit('setNotification',{
                        show: true,
                        head: "Successfully copied to the clipboard!",
                        subheader: ``,
                        success: true
                    }); 
                } catch (error) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong.",
                        subheader: error,
                        success: false
                    }); 
                }
            },
            async downloadPortalSettings() {
                const portalSettingsGet = await this.$store.state.userData.system.ref.get()
                const portalSettings = portalSettingsGet.data().portal
                this.portalLink = `https://client.complaia.com/?portal=${portalSettings.key}`
                this.fields = portalSettings.settings.form.fields
                this.htmlMeta = portalSettings.settings.form.htmlMeta
                this.appearance = portalSettings.settings.form.appearance
                this.logo = portalSettings.settings.form.logo
                this.portalOptions = portalSettings.settings.form.fields.product.expectationInput.options
                this.appearance.backgroundType = ref(this.backgroundType[this.appearance.backgroundType.id]);
                this.loading = false
                if(this.logo.logoUrl.length > 0 && this.logo.logoUrl !== undefined) {
                    this.imgPreviewSrc = this.logo.logoUrl
                }
                setTimeout(() => {
                    this.$store.commit("resetGlobalLoader");
                },200)
            },
            async savePortalSettings() {
                this.portalOptions.values = []
                this.expectationValues.forEach(expectation => {
                    if(expectation.isSelected) {
                        this.portalOptions.values.push({
                            name: expectation.name,
                            isCheckout: expectation.isCheckout
                        })
                    }
                })
                this.portalOptions.default = {
                    name: this.portalOptions.default.name,
                    isCheckout: this.portalOptions.default.isCheckout
                }
                if(this.userSubscription.pdfUpload === 'false') {
                    this.fields.product.pdfInput.isRequired = false
                    this.fields.product.pdfInput.isShowed = false
                }
                // delete old main img from storage
                if(this.logo.logoPath.length > 0 && this.logo.logoPath !== undefined && this.imgPreviewSrc.length === 0 || this.logo.logoPath.length > 0 && this.logo.logoPath !== undefined && this.$refs.mainImgUploader.files.length > 0 )
                {
                    const mainImageRef = storage.ref().child(this.logo.logoPath);
                    await mainImageRef.delete().catch((error) =>
                    {
                        console.log(error);
                    });
                    this.logo.logoUrl = "";
                    this.logo.logoPath = "";
                }

                // Upload an image
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    const storageRef = storage.ref();
                    const filename = this.filenameGenerator(10);
                    const path = `systems/${this.$store.state.userData.system.id}/logo/${filename}`;
                    const imageRef = storageRef.child(path);
                    await imageRef.put(this.$refs.mainImgUploader.files[0]);
                    const fileUrl = await imageRef.getDownloadURL();
                    this.logo.logoUrl = fileUrl;
                    this.logo.logoPath = path;
                }
                let httpsRegex = /https:/g
                let httpRegex = /http:/g
                let regex = /:\/\//g
                if(!httpsRegex.test(this.logo.returnLink)) {
                    if (httpRegex.test(this.logo.returnLink)) {
                        this.logo.returnLink = this.logo.returnLink.slice(0,4)+'s'+this.logo.returnLink.slice(4)
                    } else if (regex.test(this.logo.returnLink)) { 
                        this.logo.returnLink = 'https://'+this.logo.returnLink.slice(this.logo.returnLink.search(regex)+3)
                    } else if (this.logo.returnLink.length > 0) {
                        this.logo.returnLink = 'https://'+this.logo.returnLink
                    }
                }
                
                try {
                    const portalSettingsRef = await this.$store.state.userData.system.ref
                    portalSettingsRef.update({
                        'portal.settings.form.fields': this.fields,
                        'portal.settings.form.htmlMeta': this.htmlMeta,
                        'portal.settings.form.appearance': this.appearance,
                        'portal.settings.form.fields.product.expectationInput.options': this.portalOptions,
                        'portal.settings.form.logo': this.logo
                    })
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Portal settings successfully updated!",
                        subheader: ``,
                        success: true
                    });

                    this.$store.state.userData.system.ref.collection("Events").add(
                        {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Portal settings successfully updated.`
                    }).catch((error)=>
                    {
                    console.log(error);
                    });

                } catch(error) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong.",
                        subheader: error,
                        success: false
                    }); 
                }
            },
            checkIsShowed(object) {
                if(!object.isShowed && object.isRequired) {
                    object.isRequired = false
                }
            },
        },
        async mounted() {
            const optionsGet = await db.collection('OptionValues').doc('ExpectationsValues').get()
            this.expectationValues = optionsGet.data().values
            this.expectationValues.forEach(object => {
                let found = false
                this.portalOptions.values.forEach(option => {
                    if(option.name === object.name) {
                        found = true
                    }
                });
                object.isSelected = found
               
            })
        },
        computed:
        {
            checkIsCheckout() {
                let isCheckout = false
                this.expectationValues.forEach(expectation => {
                    if(expectation.isSelected && expectation.isCheckout) {
                        isCheckout = true
                    }
                })
                return isCheckout
            },
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>