<template>
<Transition name="page">
  <div class="flex h-full flex-col" v-if="renderPage">
    <div class="flex sm:items-baseline sm:space-x-4">
      <div @click.prevent="previousPage" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span><span> Back to basic feed</span></div>
    </div>
    <header class="relative z-10 flex flex-none items-center justify-between border-b border-gray-200 py-4 ">
      <div>
        <h1 class="text-lg font-semibold leading-6 text-gray-900">
          <time datetime="2022-01-22" class="inline">List of events for {{selected.titleDate}}</time>
        </h1>
        <p class="mt-1 text-sm text-gray-500">{{`Week ${selected.weekNum}`}}</p>
      </div>
    </header>
    <div class="flex flex-col-reverse xl:flex-row flex-auto overflow-hidden bg-white">
      <div ref="container" class="flex flex-auto flex-col overflow-auto xl:pr-2">
        <Transition name="fade">
         <EmptyState class="mt-8" v-if="selectedList.length === 0 && !this.loading && !animation"></EmptyState>
        </Transition>
          <div class="mt-4">
            <Transition name="fade">
            <ul role="list" class="divide-y divide-gray-200 overflow-auto h-30screen md:h-40screen lg:h-50screen" v-if="selectedList.length > 0 && !this.loading">
              <li v-for="(event,indx) in selectedList" :key="indx" class="py-4">
                <div class="flex space-x-3">
                  <img class="h-6 w-6 rounded-full" :src="event.user.photo" alt="Event" />
                  <div class="flex-1 space-y-1">
                    <div class="flex items-center justify-between">
                      <h3 class="text-sm font-medium">{{ event.user.name }}</h3>
                      <p class="text-sm text-gray-500 pr-4" >{{getTime(event.creationDate.seconds)}}</p>
                    </div>
                    <p class="text-sm text-gray-500" >{{event.eventName}}</p>
                  </div>
                </div>
              </li>
            </ul>
            </Transition>
          </div>
      <Spinner v-if="loading && !animation" class="mt-4"></Spinner>
      </div>
      <div class="w-full xl:w-1/2 xl:max-w-md flex-none xl:border-l border-gray-100 pt-5 xl:py-8 px-1px xl:px-8 block">
        <div class="flex items-center text-center text-gray-900">
          <button type="button" @click.prevent="changeMonth('subtract')" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <div class="flex-auto font-semibold">{{currentMonth.monthName}} {{currentMonth.year}}</div>
          <button type="button" @click.prevent="changeMonth('add')" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>Mo</div>
          <div>Tu</div>
          <div>We</div>
          <div>Th</div>
          <div>Fr</div>
          <div>Sa</div>
          <div>Su</div>
        </div>
        <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          <button v-for="(day,ddx) in days" @click="setDay(day)" :key="day.date" type="button" :class="['py-1.5 hover:bg-gray-100 focus:z-10', day.month === currentMonth.month ? 'bg-white' : 'bg-gray-50', day === selected && day.monthNum === currentMonth.month && day.fullDate !== currentDay && 'text-gray-900', day !== selected && 'text-gray-400', day === selected && 'font-medium bg-gray-100', day.fullDate === currentDay && day !== selected && 'text-purple-600', ddx === 0 && 'rounded-tl-lg', ddx === 6 && 'rounded-tr-lg', ddx === days.length - 7 && 'rounded-bl-lg', , ddx === days.length - 1 && 'rounded-br-lg' ]">
            <time :datetime="day.fullDate" :class="{'mx-auto flex h-7 w-7 items-center justify-center rounded-full': true ,'bg-blue-600': false, 'bg-gray-900': false}">{{day.day}}</time>
          </button>
        </div>


      </div>
    </div>
    
  </div>
</Transition>
</template>



<script>

import { ChevronLeftIcon, ChevronRightIcon,
// EyeIcon, 
// CheckCircleIcon, ExclamationCircleIcon, 
// CheckIcon 
} from '@heroicons/vue/solid';
import moment from 'moment';
import EmptyState from '../components/EmptyState.vue';
import Spinner  from '@/components/loadingSpinner.vue';
// import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
export default {

  data: function () {
      return {
        dbListener: null,
        eventList: [],
        selectedList: [],
        allDates: [],
        uniqueDates: [],
        selected: '',
        currentDay: '',
        animation: true,
        renderPage: false,
        days: [],
        loading: false,
        currentMonth: {
            month: 6,
            year: 2022,
            monthName: "Loading.."
        },
      }
  },
  components: { ChevronLeftIcon, ChevronRightIcon, 
  // EyeIcon, 
  EmptyState, Spinner, 
  // CheckCircleIcon, ExclamationCircleIcon, 
  // Listbox, ListboxButton, ListboxOption, ListboxOptions, CheckIcon 
  },
  created()
  {
    setTimeout(() => {
      this.renderPage = true
      
    }, 150);
    this.setCurrentMonth();
  },
  methods: {
      getTime(unix) {
        return moment.unix(unix).format('h:mm:ss A')
      },
      async getEvents(day) {
          this.animation = true
          this.selectedList = []

          setTimeout(async () => {
            this.animation = false
            this.loading = true;
            let beginOfSelectedDay = new Date(day.unix * 1000)
            let endOfSelectedDay = new Date((day.unix + 86399) * 1000)
            const getEvents = await this.$store.state.userData.system.ref.collection("Events").where('creationDate', '>=', beginOfSelectedDay).where('creationDate', '<=', endOfSelectedDay).orderBy("creationDate", "desc").get()
            if(!getEvents.empty) {
              let eventsNumber = getEvents.docs.length
              setTimeout(() => {
                getEvents.docs.forEach((event, index) => {
                  this.selectedList.push(event.data())
                  if(eventsNumber === index+1) {
                    this.loading = false
                  }
                })
              },500)
            } else {
              setTimeout(() => {
                this.loading = false
              }, 500);
            }
          }, 250);
      },
      previousPage()
      {
        this.$store.commit('setGlobalLoader',{
          show: true,
          head: "Loading your statistics..",
          subheader: `Do not close this tab.`,
          });
        setTimeout(() => {
          this.$router.push("/dashboard/feed")
        },350) 
      },
      async setDay(day)
      {
        if(day !== this.selected) {
          this.selected = day;
          this.getEvents(day)
        }
      },
      addRestOfDaysToCallendar()
      {
          let amountFromStart  = this.days[0].dayOfWeek-7*(-1);
          let amountFromEnd = this.days[this.days.length-1].dayOfWeek;
          let currentDayFromStart = moment(this.days[0].fullDate,"YYYY-MM-DD");
          let currentDayFromEnd = moment(this.days[this.days.length-1].fullDate,"YYYY-MM-DD");

          for(let i=0; i<amountFromStart-1; i++)
          {
              let currentDay = currentDayFromStart.subtract(1, 'days')
              this.days.unshift({
                  fullDate: currentDay.format("YYYY-MM-DD"), 
                  dayOfWeek: currentDay.day(),
                  year: currentDay.year(),
                  month: currentDay.month(),
                  day: currentDay.date(),
                  monthName: currentDay.format('MMMM'),
                  unix: currentDay.unix(),
                  weekNum: currentDay.week(),
                  events: [],
                  titleDate: currentDay.format("Do MMMM YYYY")
              })
          }

          for(let i=0; i<=6-amountFromEnd; i++)
          {
              let currentDay = currentDayFromEnd.add(1, 'days')
              this.days.push({
                  fullDate: currentDay.format("YYYY-MM-DD"), 
                  dayOfWeek: currentDay.day(),
                  year: currentDay.year(),
                  month: currentDay.month(),
                  day: currentDay.date(),
                  monthName: currentDay.format('MMMM'),
                  unix: currentDay.unix(),
                  weekNum: currentDay.week(),
                  events: [],
                  titleDate: currentDay.format("Do MMMM YYYY")
              })
          }

          if(this.days.length/7 >= 6)
          {
              for(let i=0; i<7;i++)
              {
                  this.days.shift();
              }
          }

          
          
      },
      getDaysOfMonth()
      {
          this.days = [];
          let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
          let amountOfDaysInMonth = moment(current,"DD-M-YYYY").daysInMonth();

          for(let i=1; i<amountOfDaysInMonth; i++)
          {
              let momentObj = moment(`${i}-${this.currentMonth.month+1}-${this.currentMonth.year}`,"D-M-YYYY")
              let day = {
                  fullDate: momentObj.format("YYYY-MM-DD"), 
                  dayOfWeek: momentObj.day(),
                  year: momentObj.year(),
                  month: momentObj.month(),
                  day: momentObj.date(),
                  monthName: momentObj.format('MMMM'),
                  unix: momentObj.unix(),
                  weekNum: momentObj.week(),
                  events: [],
                  titleDate: momentObj.format("Do MMMM YYYY")
              }
              this.days.push(day);
          }

          this.addRestOfDaysToCallendar();

      },
      setCurrentMonth()
      {
          this.currentMonth.year = moment().year();
          this.currentMonth.month = moment().month();
          this.currentMonth.monthName = moment().format('MMMM');
          this.currentDay = moment().format('YYYY-MM-DD');
          this.getDaysOfMonth();
          let currentDayDate
          for(let i = 0; i < this.days.length; i++) {
            if(this.days[i].fullDate === this.currentDay) {
              currentDayDate = this.days[i]
            }
          }
          this.setDay(currentDayDate)

      },
      changeMonth(type)
      {
          let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
          if(type === 'add')
          {
              this.currentMonth.year = moment(current,"DD-M-YYYY").add(1, 'months').year();
              this.currentMonth.month = moment(current,"DD-M-YYYY").add(1, 'months').month();
              this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(1, 'months').format('MMMM');
          }
          if(type === 'subtract')
          {
              this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(1, 'months').year();
              this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(1, 'months').month();
              this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(1, 'months').format('MMMM');
          }
          this.getDaysOfMonth();
      },
  },
}
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.2s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
    .page-enter-active,
    .page-leave-active {
    transition: opacity 0.5s ease;
    }

    .page-enter-from,
    .page-leave-to {
    opacity: 0;
    }
</style>