<template>
    <div class="container flex min-h-screen mt-6 mb-6 flex-col justify-items-center mx-auto px-4 sm:px-6 lg:px-8">
        <div>
            <a href="https://complaia.com/"><img class="h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia.com - complaint system for buisness" /></a>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
             Join to {{this.registerForm.company.name}} system
            </h2>
        </div>

        <div class="mt-6">
          <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Account details</h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Create your login credentials.
                  </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST" id="form-1">
                    <div class="grid grid-cols-6 gap-6">

                      <div class="col-span-6 sm:col-span-4">
                        <label for="email-address" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.email === true}">Email address*</label>
                        <input type="text" name="email-address" v-model="registerForm.email" id="email-address" autocomplete="email" disabled :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md opacity-30': true, 'border-red-300': errors.email === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.email">Your email is incorrect. (Example: joedoe@gmail.com)</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="first-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.password === true}">Password*</label>
                        <input type="password" name="pass" id="pass" v-model="password" autocomplete="new-password" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.password === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.password">Your password must be at least 6 characters.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.repeatPass === true}">Repeat password*</label>
                        <input type="password" name="repeatpass" id="repeatpass" v-model="repeatPassword" autocomplete="new-password" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md': true, 'border-red-300': errors.repeatPass === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.repeatPass">Both password fields must be the same.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="first-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.name === true}">First name*</label>
                        <input type="text" name="first-name" id="first-name" v-model="registerForm.name" disabled autocomplete="given-name" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md opacity-30': true, 'border-red-300': errors.name === true}">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.name">Field cannot be empty.</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" :class="{'block text-sm font-medium text-gray-700': true, 'text-red-600': errors.surname === true}">Last name*</label>
                        <input type="text" name="last-name" id="last-name" v-model="registerForm.surname" disabled autocomplete="family-name" :class="{'mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md opacity-30': true, 'border-red-300': errors.surname === true, }">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.surname">Field cannot be empty.</p>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone number</label>
                        <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' name="phone-number" id="phone-number" v-model="registerForm.phoneNumber" autocomplete="tel" class="mt-1 focus:ring-blue-400 focus:border-blue-400 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                        <p class="mt-2 text-sm text-red-600" v-if="errors.phone">Your phone number is incorrect or have no country code. (Example: +48123456789) </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
        <div>
          <div class="mt-6 relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-6">
            <p class="mt-2 text-sm text-gray-600">
              By creating an account I accept 
              {{ ' ' }}
              <router-link to="/" class="font-medium text-blue-400  hover:text-blue-500 transition-all">terms of use & privacy policy.</router-link>
            </p>
        </div>
        <div class="flex justify-end mt-2  text-xs text-gray-500">
          <p>
             You will be asked to verify your credit card in the next step. You will not be charged until your free trial ends. You can resign at any moment.
          </p>
        </div>
        <div class="flex justify-end mt-6 mb-3 text-red-600 text-sm" v-if="errors.postClickError">
          {{postErrorInfo}}
        </div>
        <div class="flex justify-end mt-6 mb-6">
          <button type="submit" @click.prevent="validateForm" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
            Join to system
          </button>
        </div>
        <Loader v-if="showLoader" :header="loaderTexts.header" :subheader="loaderTexts.subheader">

        </Loader>

       


    </div>
</template>

<script>
// Import Stripe.js
// import {loadStripe} from '@stripe/stripe-js';
// Import Axios
import axios from 'axios';
// Import loader
import Loader from '@/components/Loader.vue'
// Import Firebase
import {db} from '@/firebase/gfbconf.js';

export default {
  name: "Register",
  data: function () {
    return {
      loaderTexts: {
        header: "Loading..",
        subheader: "This may take a few seconds, please Do not close this page."
      },
      invId: "",
      showLoader: true,
      errors: {
        password: false,
        repeatPass: false,
        email: false,
        name: false,
        surname: false,
        phone: false,
        company: false,
        postClickError: false
      },
      postErrorInfo: "",
      password: "",
      repeatPassword: "",
      systemCreatorEmail: "",
      system: "",
      registerForm: {
        name: "",
        surname: "",
        phoneNumber: "",
        email: "",
        company: {
          name: "",
          taxIdNum: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
          country: ""
        }
      }
    }
  },
  components: {
    Loader
  },
  methods: {
    async createAccount(){
      this.showLoader = true;
      this.loaderTexts.header = "Creating your account.."
      this.loaderTexts.subheader = "Preparing Complaia System.. please Do not close this page."
      const creationResult = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/setup/initialize/newUser', {
        password: this.password,
        system: this.system,
        registerForm: this.registerForm,
        invId: this.invId
      });
      if(creationResult.data.status === 'success')
      {
        this.loaderTexts.header = "Logging to system.."
        this.loaderTexts.subheader = "So close now.. Redirecting.."
        // Hide previously created errors. 
        this.postErrorInfo = "";
        this.errors.postClickError = false;
        await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
            attendantEmail: this.systemCreatorEmail,
            to: this.systemCreatorEmail,
            messageType: "teamAccept",
            payload: {
              invitedName: this.registerForm.name,
              invitedSurname: this.registerForm.surname,
            }
          })
        // Log into current user account;
        await this.$store.dispatch("login", {
            email: this.registerForm.email,
            password: this.password,
        });
        this.$router.push(`${this.$store.state.url}`);
      }else{
        this.postErrorInfo = creationResult.data.info;
        this.errors.postClickError = true;
        this.loaderTexts.header = "Ups going back.."
        this.loaderTexts.subheader = "Cannot create new account. More info after we hide this popup."

        setTimeout(()=>
        {
          this.showLoader = false;
        },1000);
      }
    },
    downloadInvitation(){
      this.invId = this.$route.query.id
      let invitationId = this.$route.query.id
            db.collection("Invitations")
            .doc(invitationId)
            .get()
            .then((snapshot) => {
              if(snapshot.exists){
             const data = snapshot.data();
                if(data !== undefined && data.used === false){
                this.registerForm.name = data.name
                this.registerForm.surname = data.surname
                this.registerForm.phoneNumber = data.phoneNumber
                this.registerForm.email = data.email
                this.registerForm.company = data.company
                this.system = data.system
                this.showLoader = false
                this.getSystemCreator(data.system)
                }else{
                    this.$router.push("/");
                }
              }else{
                    this.$router.push("/");
                }
            })
            .catch((error) => {
            // Error when getting category
            console.log(error);
            });
    },
    async getSystemCreator() {
      const res = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/get/systemCreator', {
        system: this.system,
      });
      if(res.data.status === "ok"){
        this.systemCreatorEmail = res.data.systemCreatorEmail
      }
    },
    validateForm()
    {
      // Check if email is OK
      if( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.registerForm.email))
      {
        this.errors.email = false;
        // Check if pass is OK
        if(this.password.length >= 6)
        {
          this.errors.password = false;
          // Check if repeat pass is the same as pass.
          if(this.password === this.repeatPassword)
          {
            this.errors.repeatPass = false;
            // Check if name is not empty
            if(this.registerForm.name.length>0)
            {
              this.errors.name = false;
              // Check if surname is not empty
              if(this.registerForm.surname.length>0)
              {
                this.errors.surname = false;
                // Check company name
                if(this.registerForm.company.name.length>0)
                {
                  this.errors.company = false;
                  this.createAccount();
                }
                else
                {
                  this.errors.company = true;
                  this.scrollTo('form-2')
                  return 0;
                }
              }
              else
              {
                this.errors.surname = true;
                this.scrollTo('form-1')
                return 0;
              }
            }
            else
            {
              this.errors.name = true;
              this.scrollTo('form-1')
              return 0;
            }
          }
          else
          {
            this.errors.repeatPass = true;
            this.scrollTo('form-1')
            return 0;
          }
        }
        else
        {
          this.errors.password = true;
          this.scrollTo('form-1')
          return 0;
        }
      }
      else
      {
        this.errors.email = true;
        this.scrollTo('form-1')
        return 0;
      }
    },
  },
  created()
        {
          this.downloadInvitation();
        },
  updated(){
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
