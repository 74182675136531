<template>
    <form class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 sm:space-y-5">
        <div>
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Billing</h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">Information about your Complaia plan and Stripe billing.</p>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5" v-if="$store.state.userData.systemCreator">
                <span class="text-sm">Current subscription period:</span> <span class="text-sm font-medium">{{subPeriod}}</span>
            </div>
            <div class="pt-5" v-if="$store.state.userData.systemCreator">
                <div class="flex justify-start">
                    <button type="submit" @click.prevent="goToStripeCustomerPortal"  :class='{" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white": true, "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500": true}'>Manage subscription</button>
                </div>
            </div>
            <div class="mt-5 select-none text-xs" v-if="!$store.state.userData.systemCreator">
                <span>You are not allowed to view this page. Only administrator can manage billing details.</span>
            </div>
        </div>
        </div>
    </form>
</template>
<script>
    import moment from 'moment';
    //import axios from 'axios';
    import { firebase } from "@/firebase/gfbconf";
    export default {
        name: "Billing",
        data: function () {
        return {
            subscription: null
        }
        },
        methods: {
            async goToStripeCustomerPortal()
            {
                
                this.$store.state.globalLoader.show = true;
                const functionRef = firebase
                .app()
                .functions('us-central1')
                .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
                const { data } = await functionRef({
                returnUrl: window.location.origin,
                locale: "auto", // Optional, defaults to "auto"
                });
                window.location.assign(data.url);
                this.$store.state.globalLoader.show = false;
            }
        },
        computed:{
            subPeriod()
            {
                const start = moment.unix(this.$store.state.userSubscription.current_period_start._seconds).format("DD.MM.YYYY");
                const end = moment.unix(this.$store.state.userSubscription.current_period_end._seconds).format("DD.MM.YYYY");
                return `${start} - ${end}`;
            }
        },
        mounted()
        {
           
        },
        created()
        {
            
        }
    };
</script>