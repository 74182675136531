<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
-->
<template>
  <div class="bg-white">
    <!-- Header and Page Header -->
    <div class="relative">
      <!-- Header -->
      <header class="relative z-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between border-b border-gray-200 py-6 md:justify-start md:space-x-10">
            <div class="flex justify-start lg:w-0 lg:flex-1">
              <a href="https://app.complaia.com/">
                <span class="sr-only">Complaia Complaint Systems</span>
                <img class="h-8 w-auto sm:h-10" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia complaint system in web" />
              </a>
            </div>
          </div>
        </div>


      </header>

      <!-- Page Header -->
      <div class="relative mx-auto max-w-2xl py-16 px-4 sm:px-6 lg:max-w-7xl lg:py-16 lg:px-8">
        <div class="relative">
          <h1 v-if="this.$route.query.currentplan !== 'undefined'" class="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">Your <span class="text-purple-700">subscription</span> has expired.</h1>
          <h1 v-if="this.$route.query.currentplan === 'undefined'" class="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">Choose the best <span class="text-purple-700">subscription</span> plan for you.</h1>
          <p v-if="this.$route.query.currentplan !== 'undefined'" class="mt-6 max-w-2xl text-xl text-gray-500">Select a plan to regain access to the system.</p>
          <p v-if="this.$route.query.currentplan === 'undefined'" class="mt-6 max-w-2xl text-xl text-gray-500">Select a plan to gain access to the system.</p>
        </div>
      </div>
    </div>

    <main>
      <!-- Pricing Section -->
      <section class="relative" aria-labelledby="pricing-heading">
        <h2 id="pricing-heading" class="sr-only">Pricing</h2>

        <!-- Tiers -->
        <div class="mx-auto max-w-2xl space-y-12 px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:space-y-0 lg:px-8">
          <div v-for="tier in pricing.tiers" :key="tier.title" class="relative flex flex-col rounded-2xl bg-white p-8 shadow-sm" :class="[tier.mostPopular? 'border-purple-500 border-2' : 'border-gray-200 border']">
            <div class="flex-1">
              <h3 class="text-xl font-semibold text-gray-900">{{ tier.title }}</h3>
              <p v-if="tier.mostPopular" class="absolute top-0 -translate-y-1/2 transform rounded-full bg-purple-800 py-1.5 px-4 text-sm font-semibold text-white">Previous plan</p>
              <p class="mt-4 flex items-baseline text-gray-900">
                <span class="text-5xl font-bold tracking-tight">${{ tier.price }}</span>
                <span class="ml-1 text-xl font-semibold">{{ tier.frequency }}</span>
              </p>
              <p class="mt-6 text-gray-500">{{ tier.description }}</p>

              <!-- Feature list -->
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="feature in tier.features" :key="feature" class="flex items-center">
                  <CheckIcon class="h-5 w-5 flex-shrink-0 text-purple-500" aria-hidden="true" />
                  <span class="ml-3 text-gray-500 text-xs font-medium">{{ feature }}</span>
                </li>
              </ul>
            </div>

            <a @click.prevent="continuePlan(tier)" :class="[tier.mostPopular ? 'bg-purple-500 text-white hover:bg-purple-600' : 'bg-purple-50 text-purple-700 hover:bg-purple-100', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium cursor-pointer']">{{ tier.cta }}</a>
          </div>
        </div>
      </section>



      <!-- FAQ -->
      <section aria-labelledby="faq-heading" class="mx-auto max-w-2xl divide-y divide-gray-200 py-24 px-4 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8">
        <h2 id="faq-heading" class="text-3xl font-bold tracking-tight text-gray-900">Frequently asked questions</h2>
        <div class="mt-8">
          <dl class="divide-y divide-gray-200">
            <div v-for="faq in faqs" :key="faq.id" class="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base font-medium text-gray-900 md:col-span-5">{{ faq.question }}</dt>
              <dd class="mt-2 md:col-span-7 md:mt-0">
                <p class="text-base text-gray-500">{{ faq.answer }}</p>
              </dd>
            </div>
          </dl>
        </div>
      </section>
    </main>

    <!-- Footer -->
    <footer class="bg-gray-50" aria-labelledby="footer-heading">
      <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">

          </div>
        </div>
        <div class="mt-12 border-t border-gray-200 pt-8">
          <p class="text-base text-gray-400 xl:text-center">&copy; 2022 Complaia Systems Sp. z o.o.  All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-auto sm:p-6">
            <div class="relative mx-4 border-b border-gray-200 mb-6">
              <div class="relative">
                <h1 class="text-xl font-medium text-gray-900 sm:leading-none lg:text-xl">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </h1>
                <p class="mt-1 pb-4 max-w-2xl text-md text-gray-500">Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
              </div>
            </div>
              <!-- Tiers -->
              <div class="mx-auto max-w-2xl space-y-12 px-4 lg:max-w-4xl lg:flex lg:space-y-0">
                <!-- Previous tier -->
                <div class="relative flex flex-1 flex-col rounded-2xl bg-white p-8 shadow-sm" :class="[pricing.tiers[this.currentPlan.index].mostPopular] ? 'border-purple-500 border-2' : 'border-gray-200 border'">
                  <div class="flex-1">
                    <h3 class="text-xl font-semibold text-gray-900">{{ pricing.tiers[this.currentPlan.index].title }}</h3>
                    <p v-if="pricing.tiers[this.currentPlan.index].mostPopular" class="absolute top-0 -translate-y-1/2 transform rounded-full bg-purple-800 py-1.5 px-4 text-sm font-semibold text-white">Previous plan</p>
                    <p class="mt-4 flex items-baseline text-gray-900">
                      <span class="text-5xl font-bold tracking-tight">${{ pricing.tiers[this.currentPlan.index].price }}</span>
                      <span class="ml-1 text-xl font-semibold">{{ pricing.tiers[this.currentPlan.index].frequency }}</span>
                    </p>
                    <p class="mt-6 text-gray-500">{{ pricing.tiers[this.currentPlan.index].description }}</p>

                    <!-- Feature list -->
                    <ul role="list" class="mt-6 space-y-4">
                      <li v-for="feature in pricing.tiers[this.currentPlan.index].features" :key="feature" class="flex items-center">
                        <CheckIcon class="h-5 w-5 flex-shrink-0 text-purple-500" aria-hidden="true" />
                        <span class="ml-3 text-gray-500 text-xs font-medium">{{ feature }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex items-center justify-center">
                  <ArrowNarrowRightIcon class="h-12 w-12 ml-5 mr-5 text-purple-500 hidden lg:block" aria-hidden="true" />
                  <ArrowNarrowDownIcon class="h-12 w-12 ml-5 mr-5 text-purple-500 block lg:hidden" aria-hidden="true" />
                </div>
                <!-- New tier -->
                <div class="relative flex flex-1 flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
                  <div class="flex-1">
                    <h3 class="text-xl font-semibold text-gray-900">{{ pricing.tiers[this.selectedPlanIndex].title }}</h3>
                    <p class="mt-4 flex items-baseline text-gray-900">
                      <span class="text-5xl font-bold tracking-tight">${{ pricing.tiers[this.selectedPlanIndex].price }}</span>
                      <span class="ml-1 text-xl font-semibold">{{ pricing.tiers[this.selectedPlanIndex].frequency }}</span>
                    </p>
                    <p class="mt-6 text-gray-500">{{ pricing.tiers[this.selectedPlanIndex].description }}</p>

                    <!-- Feature list -->
                    <ul role="list" class="mt-6 space-y-4">
                      <li v-for="feature in pricing.tiers[this.selectedPlanIndex].features" :key="feature" class="flex items-center">
                        <CheckIcon class="h-5 w-5 flex-shrink-0 text-purple-500" aria-hidden="true" />
                        <span class="ml-3 text-gray-500 text-xs font-medium">{{ feature }}</span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            <div class="mt-6 sm:flex mx-4 sm:flex-row-reverse border-t border-gray-200 pt-4">
              <button type="button" class="bg-purple-600 font-medium hover:bg-purple-700  w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto text-sm" @click.prevent="createStripeCheckout(this.selectedPlanIndex)">Confirm</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto text-sm" @click.prevent="modalState()">Cancel</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <Loader v-if="showLoader" :header="loaderTexts.header" :subheader="loaderTexts.subheader"></Loader>
</template>

<script>

import {db} from '@/firebase/gfbconf.js';
import { CheckIcon, 
ArrowNarrowRightIcon, 
ArrowNarrowDownIcon, 
// PlusCircleIcon 
} from '@heroicons/vue/outline';
import { Dialog, DialogOverlay, 
// DialogTitle, 
TransitionChild, TransitionRoot } from '@headlessui/vue';
import Loader from '@/components/Loader.vue';
import {loadStripe} from '@stripe/stripe-js';


export default {
  components: {
    CheckIcon,
    Dialog,
    DialogOverlay,
    // DialogTitle,
    TransitionChild,
    TransitionRoot,
    // MinusCircleIcon,
    ArrowNarrowRightIcon,
    ArrowNarrowDownIcon,
    // PlusCircleIcon
    Loader,
  },
  name: "selectPlansExpired",
  data: function () {
    return {
      currentPlan: '',
      loaderTexts: {
        header: "Loading..",
        subheader: "This may take a few seconds, please Do not close this page."
      },
      showLoader: false,
      showModal: false,
      selectedPlanIndex: null,
      diffrencesGlobal: [],
      pricing: {
      tiers: [
        {
          title: 'Starter',
          planId: 'ComplaiaStarter',
          index: 0,
          price: 29.99,
          url: '/register?plan=ComplaiaStarter',
          frequency: '/month',
          description: 'Most often chosen by small companies.',
          features: [
            '1 user',
            'Basic feed system',
            'Up to 2 images per complaint (4MB per file)',
            'Online complaint form & customer portal',
            'Customer chat',
            'Notification system',
            '6-month complaint history',
          ],
          cta: 'Select',
          mostPopular: this.$route.query.currentplan === "ComplaiaStarter" ? true : false,
        },
        {
          title: 'Standard',
          planId: 'ComplaiaStd',
          index: 1,
          price: 54.99,
          url: "/register?plan=ComplaiaStd",
          frequency: '/month',
          description: 'Most essential complaint tools for rapidly growing business',
          features: [
            'Up to 5 users',
            'User role system',
            'Advanced feed system',
            'Up to 4 images per complaint (6MB per file)',
            'PDF files support',
            'Online complaint form & customer portal',
            'Online form and customer portal customization',
            'Customer chat',
            'Notification system',
            'Analytics',
            '12-month complaint history',
          ],
          cta: 'Select',
          mostPopular: this.$route.query.currentplan === "ComplaiaStd" ? true : false,
        },
        {
          title: 'Pro',
          planId: 'ComplaiaPro',
          index: 2,
          price: 99.99,
          url: '/register?plan=ComplaiaPro',
          frequency: '/month',
          description: 'The perfect plan for large companies with greater needs',
          features: [
            'Up to 10 users',
            'User role system',
            'Advanced feed system',
            'Up to 8 images per complaint (8MB per file)',
            'PDF files support',
            'Printable labels',
            'Online complaint form & customer portal',
            'Online form and customer portal customization',
            'Customer chat',
            'Notification system',
            'Analytics',
            '36-month complaint history',
          ],
          cta: 'Select',
          mostPopular: this.$route.query.currentplan === "ComplaiaPro" ? true : false,
        },
      ],
    },
    faqs: [
      {
        id: 1,
        question: "What is Complaia?",
        answer:
          "Complaia is a set of tools for handling complaint processes and their automation. Put a link to your portal in your webshop and Complaia will do the rest.",
      },
      {
        id: 2,
        question: "Why I should use Complaia?",
        answer:
          "Dealing with dissatisfied customers is stressful. Complaia allows you to resolve complaints efficiently. Have control in any, even the most difficult situations.",
      },
      {
        id: 3,
        question: "Can I test the application for free first?",
        answer:
          "You have 7 days of free access. Complaia allows you to unsubscribe at any time.",
      },
      {
        id: 4,
        question: "The pro plan is not enough for me. Can I ask for more?",
        answer:
          "For large companies, we offer special installations outside the service's main servers. You can also request additional functionality not available in regular plans. Contact our sales department: sales@complaia.com .",
      },
      // More questions...
    ]
    }
  },
  mounted() {
    this.pricing.tiers.forEach(plan => {
      if(this.$route.query.currentplan === plan.planId) {
        this.currentPlan = plan
      }
    });
  },
  methods: {
    continuePlan(selectedPlan) {
      if(selectedPlan.index < this.currentPlan.index) {
        this.selectedPlanIndex = selectedPlan.index
        this.modalState()
      } else {
        this.createStripeCheckout(selectedPlan.index)
      }
    },
    modalState() {
      this.showModal = !this.showModal
    },
    async createStripeCheckout(planIndex) {
      if(this.$store.state.user !== null) {
        this.showLoader = true;
        this.loaderTexts.header = "Let's go to Stripe page.."
        this.loaderTexts.subheader = "So close now.. Redirecting.."
        await loadStripe('pk_live_51KWlnFF0lxeRGWNEk81sePwYq3EfdeqMuscoVFQE7Dkai3TJKxiSGRAOGAFbxkJHMKCsBOZGopXuUPtbH9BFQRSN00W3KokPUx');
        // Create link to payment
        const docRef = await db
          .collection('UsersData')
          .doc(this.$store.state.user.uid)
          .collection('checkout_sessions')
          .add({
            price: this.pricing.tiers[planIndex].planId,
            success_url: 'https://app.complaia.com/dashboard/feed',
            cancel_url: 'https://app.complaia.com/?subscriptionFailed=true',
            trial_from_plan: false,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      } else {
        this.showLoader = true;
        this.loaderTexts.header = "Session expired"
        this.loaderTexts.subheader = "Redirecting to login site.."
        setTimeout(() => {
          this.$router.push(`https://app.complaia.com/`);
        },1500)
      }
    },
  }
}


</script>