<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Profile</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">This information will be displayed publicly so be careful what you share.</p>
        </div>
        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> First name </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" v-model="profile.name" name="first-name" id="first-name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Last name </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" name="last-name" v-model="profile.surname" id="last-name" autocomplete="family-name" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button type="submit" @click.prevent="updateBasicCredentials" :class='{"ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white": true, "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500": (this.$store.state.userData.name !== this.profile.name || this.$store.state.userData.surname !== this.profile.surname) && (this.profile.name.length > 0 && this.profile.surname.length > 0), "bg-purple-200 cursor-default pointer-events-none": ($store.state.userData.name === profile.name && $store.state.userData.surname === profile.surname) || ((this.profile.name.length === 0 || this.profile.surname.length === 0))}'>Save basic credentials</button>
        </div>
      </div>

      <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Team communication</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Only used for in-team communication.</p>
        </div>
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="phone" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Phone number </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" name="phone" id="phone" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="profile.phoneNumber" autocomplete="tel" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
              <p class="mt-2 text-sm text-red-600" v-if="errors.phoneNumber">Your phone number is incorrect or have no country code. (Example: +48123456789)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="submit" @click.prevent="errorCheckPhoneNumber()" :class='{"ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white": true, "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500": $store.state.userData.phoneNumber !== profile.phoneNumber && profile.phoneNumber.length > 0, "bg-purple-200 pointer-events-none cursor-default": $store.state.userData.phoneNumber === profile.phoneNumber || profile.phoneNumber.length === 0}'>Update phone number</button>
      </div>
    </div>

      <div class="pt-5 pb-5">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Password</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Change password to your Complaia account ({{$store.state.userData.email}}).</p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Actual password </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="password" autocomplete="new-password" v-model="password.old" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
              <p class="mt-2 text-sm text-gray-500" >Current Complaia account password.</p>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> New password </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="password" autocomplete="new-password" v-model="password.new" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
              <p class="mt-2 text-sm text-gray-500" >At least 6 characters.</p>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Repeat new password</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="password" v-model="password.newRepeat" autocomplete="new-password" class="max-w-lg block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:max-w-xs text-sm border-gray-300 rounded-md" />
              <p class="mt-2 text-sm text-gray-500" >Field should be the same as "New password".</p>
            </div>
            
          </div>
        </div>
      </div>
    <div class="pt-5">
      <div class="flex justify-end">
        <button type="submit" @click.prevent="updatePassword" :class='{"ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white": true, "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500": password.old.length>=6 & password.new.length>=6 && password.newRepeat.length>=6 && password.new === password.newRepeat, "bg-purple-200 cursor-pointer pointer-events-none": password.new.length<6 ||  password.newRepeat.length<6 || password.new !== password.newRepeat || password.old.length<6}'>Change password</button>
      </div>
    </div>
  </form>
</template>
<script>
  import {db,auth,firebase} from '@/firebase/gfbconf';
  export default {
    name: "AccountSettings",
    data: function () {
      return {
        profile: {
          name: "",
          surname: "",
          phoneNumber: ""
        },
        password: {
          old: "",
          new: "",
          newRepeat: ""
        },
        errors:{
          phoneNumber: false,
        }
      }
    },
    methods: {
      async updatePassword()
      {
        if((this.password.new === this.password.newRepeat) && this.password.new.length>=6 && this.password.newRepeat.length>=6 && this.password.old.length>= 6)
        {
          const credential = firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            this.password.old
          );
          try
          {
            await auth.currentUser.reauthenticateWithCredential(credential);

            try
            {
              const user = firebase.auth().currentUser;
              const newPassword = this.password.new;
              await user.updatePassword(newPassword);
              this.$store.commit('setNotification',{
                show: true,
                head: "Successfully saved!",
                subheader: "Password has been changed.",
                success: true
              });
              this.password = {
                old: "",
                new: "",
                newRepeat: ""
              }
              
            }
            catch(error)
            {
              console.log(error);
              this.$store.commit('setNotification',{
                show: true,
                head: "Failed.",
                subheader: "New password is incorrect.",
                success: false
              });
            }
          }
          catch(error)
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Failed.",
              subheader: "Actual password is invalid.",
              success: false
            });
          }
          
          
        }
      },
      async updateBasicCredentials()
      {
        if((this.$store.state.userData.name !== this.profile.name || this.$store.state.userData.surname !== this.profile.surname) && (this.profile.name.length > 0 && this.profile.surname.length > 0))
        {
          this.$store.state.userData.name = this.profile.name;
          this.$store.state.userData.surname = this.profile.surname;
          const displayName = `${this.profile.name} ${this.profile.surname}`;
          const photoUrl = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(displayName)}.svg`;
          await db.collection("UsersData").doc(this.$store.state.user.uid).update({
            "name": this.profile.name,
            "surname": this.profile.surname,
            "photoURL": photoUrl
          });
          try
          {
            await auth.currentUser.updateProfile({
              displayName: displayName,
              photoURL: photoUrl
            });
            let sys = await db.collection("Systems").doc(this.$store.state.userData.system.id).get()
            let sysData = sys.data()
            for (let i = 0; i < sysData.users.length; i++) {
                if(sysData.users[i].id === this.$store.state.user.uid){
                  sysData.users[i].displayName = displayName
                }
            }
            for(let i=0; i<this.$store.state.systemData.users.length;i++)
            {
              if(this.$store.state.systemData.users[i].id.toString() === this.$store.state.user.uid.toString())
              {
                this.$store.state.systemData.users[i].displayName = displayName;
              }
            }
            await db.collection("Systems").doc(this.$store.state.userData.system.id).update({
              "users": sysData.users
            });
          }
          catch(error)
          {
            console.log(error);
          }
          this.$store.commit('setNotification',{
            show: true,
            head: "Successfully saved!",
            subheader: "User credentials has been saved.",
            success: true
          });
          this.$store.state.user.displayName = displayName;
          this.$store.state.user.photoURL = photoUrl;
        }
      },
      // edit phone number (ADD regex)
      async updatePhoneNumber()
      {
        if(this.$store.state.userData.phoneNumber !== this.profile.phoneNumber)
        {
          this.$store.state.userData.phoneNumber = this.profile.phoneNumber;
          await db.collection("UsersData").doc(this.$store.state.user.uid).update({
            "phoneNumber": this.profile.phoneNumber
          });
          this.$store.commit('setNotification',{
            show: true,
            head: "Successfully saved!",
            subheader: "Phone number has been saved.",
            success: true
          });
        }
      },
      errorCheckPhoneNumber(){
          if (
            /^([+][\s0-9]+)(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(
              this.profile.phoneNumber
            )
          ) {
            this.errors.phoneNumber = false;
            this.updatePhoneNumber();
          }else{
            this.errors.phoneNumber = true;
            return 0;
          }
      },
    },
    mounted()
    {
      if(this.$store.state.userData !== null)
      {
        this.profile.name = this.$store.state.userData.name;
        this.profile.surname = this.$store.state.userData.surname;
        this.profile.phoneNumber = this.$store.state.userData.phoneNumber;
      }
    }
  };
</script>
