<template>
    <div v-if="loaded">
        <div>
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Manage group of {{attrib.name}}</h2>
        </div>
        <div class="flex sm:items-baseline sm:space-x-4 mt-2 mb-2">
          <router-link to="/dashboard/product/attributes" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back</router-link>
        </div>
        <div class="rounded-md bg-blue-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">Removing attributes will not affect their presence in products that were created before. </p>
                    <p class="mt-3 text-sm md:mt-0 md:ml-6">
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-6 flex">
            <div class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Value name</label>
                <input type="text" v-model="newAttribName" name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="New value" />
            </div>
            <button type="button" @click.prevent="updateAttributeGroup" class="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Add value</button>
        </div>
        <div>
            <h2 class="text-gray-500 text-xs mt-4 mb-4 font-medium uppercase tracking-wide">Available values in group</h2>
        </div>
        <div v-if="attrib.list.length !== 0">
            <div class="flow-root mt-6">
                <ul role="list" class="-my-5 divide-y divide-gray-200">
                    <li v-for="value in attrib.list" :key="value.id" class="py-4">
                        <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                                <div :class="[ 'bg-yellow-200 flex-shrink-0 flex items-center justify-center w-16 p-0.5 rounded-md text-black text-sm font-medium select-none']">
                                    {{attrib.name.substring(0,3).toUpperCase()}}
                                </div>
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    {{ value.name }}
                                </p>
                                <p class="text-sm text-gray-500 truncate">
                                    ID: {{ value.id }}
                                </p>
                            </div>
                            <div>
                                <button @click="prepareForDelete(value)" class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"> Delete </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <emptyState class="mt-2" v-if="attrib.list.length === 0"></emptyState>
        <div>
            <h2 class="text-gray-500 text-xs mt-4 mb-4 font-medium uppercase tracking-wide">Modify group</h2>
        </div>
        <div>
            <button type="button" @click.prevent="deleteAskGroup = true" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete attribute group</button>
        </div>
        <askForDelete v-if="deleteAsk" :modalData="modalDataValuesText" @accept="deleteValueFromDatabase" @closeModal="deleteAsk = false" ></askForDelete>
        <askForDelete v-if="deleteAskGroup" :modalData="modalDataGroupText" @accept="deleteGroupFromDatabase" @closeModal="deleteAskGroup = false" ></askForDelete>
        
    </div>
</template>

<script>
    //import axios from 'axios';
    import moment from 'moment';
    import emptyState from '@/components/EmptyState.vue';
    import { InformationCircleIcon } from '@heroicons/vue/solid';
    import askForDelete from '@/components/AlertModal.vue';
    import {db, firebase} from "@/firebase/gfbconf.js"
    export default {
        name: "Modify Attributes",
        components: {InformationCircleIcon, askForDelete, emptyState},
        data()
        {
            return {
                valueToDelete: null,
                deleteAskGroup: false,
                deleteAsk: false,
                modalDataValuesText: {
                  headerText: "Delete value of attribute",
                  bodyText: "Are you sure to delete selected value? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                },
                modalDataGroupText: {
                  headerText: "Delete attribute",
                  bodyText: "Are you sure to delete selected attribute? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                },
                newAttribName: '',
                attrib: null,
                loaded: false,
            }
        },
        created()
        {
            this.getAttribGroup();
        },
        methods:
        {
            async deleteGroupFromDatabase()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Deleting attribute group..",
                    subheader: `Do not close this tab.`,
                });
                try{
                    for(let i=0; i<this.attrib.list.length;i++)
                    {
                        let currentValue = this.attrib.list[i];
                        await currentValue.ref.delete();
                    }
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").doc(this.$route.query.attrib).delete();
                    this.$store.state.userData.system.ref.collection("Events").add(
                        {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Deleted group of attributes: ${this.attrib.name}.`
                    }).catch((error)=>
                        {
                        console.log(error);
                        });
                    this.$router.push('/dashboard/product/attributes')
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Something went wrong..",
                        subheader: `Error occured when deleting group of attributes.`,
                        success: false
                    }); 
                    this.$router.push('/dashboard/product/attributes');
                }

            },
            async deleteValueFromDatabase()
            {
                if(this.valueToDelete !== null)
                {
                    try{
                        await this.valueToDelete.ref.delete();
                        await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").doc(this.$route.query.attrib).update({
                            list: firebase.firestore.FieldValue.arrayRemove(this.valueToDelete)
                        })
                        this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Deleted value option: ${this.valueToDelete.name} in ${this.attrib.name}.`
                        }).catch((error)=>
                        {
                        console.log(error);
                        });
                        this.valueToDelete = null;
                        this.getAttribGroup();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Value of attribute deleted with success!",
                            subheader: `Value has been removed. Removing attributes will not affect their presence in products that were created before.`,
                            success: true
                        });  
                    }
                    catch(error)
                    {
                        console.log(error);
                    }

                }

            },
            prepareForDelete(value)
            {
                this.valueToDelete = value;
                this.deleteAsk = true;
            },
            async calculateId()
            {
                const newId = Math.floor(Math.random() * 10000)+1;
                const freeId = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Attributes").doc(newId.toString()).get();
                return {
                    id: newId,
                    exists: freeId.exists
                }
            },
            async getAttribGroup()
            {
                this.loaded = false;
                const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").doc(this.$route.query.attrib).get();
                this.attrib = result.data();
                this.loaded = true;
            },
            async updateAttributeGroup()
            {
                if(this.newAttribName.length === 0)
                {
                    return 0;
                }
                let freeId = null;
                for(let i=0; i < 999; i++)
                {
                    const resultOfGettingId = await this.calculateId();
                    if(!resultOfGettingId.exists)
                    {
                        freeId = resultOfGettingId;
                        break;
                    }
                }
                if(freeId !== null)
                {
                    let newAttribute = {
                        name: this.newAttribName,
                        id: parseInt(freeId.id),
                        type: "product",
                        creationDate: moment().toDate(),
                        createdBy: this.$store.state.user.displayName, 
                        childOf: this.attrib.name,
                        groupOfAttributes: {
                            ref: db.collection("Systems").doc(this.$store.state.userData.system.id).collection("GroupsOfAttributes").doc(this.$route.query.attrib),
                            id: this.$route.query.attrib
                        }
                    };
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Attributes").doc(freeId.id.toString()).set(newAttribute);
                    await newAttribute.groupOfAttributes.ref.update({
                        'list': firebase.firestore.FieldValue.arrayUnion(
                            {
                                name: newAttribute.name,
                                id: newAttribute.id,
                                ref: db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Attributes").doc(freeId.id.toString())
                            }
                        )
                    })
                    this.$store.state.userData.system.ref.collection("Events").add(
                        {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Added new value option: ${newAttribute.name} in ${this.attrib.name}.`
                    }).catch((error)=>
                    {
                    console.log(error);
                    });
                    this.getAttribGroup();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "New value of attribute created!",
                        subheader: `You can now choose it, when creating new product.`,
                        success: true
                    });
                    this.newAttribName = "";
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Category cannot be created!",
                        subheader: `Could not find free id.`,
                        success: false
                    });
                }

                
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>