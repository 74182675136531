<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
-->
<template>
  <div class="bg-white">
    <!-- Header and Page Header -->
    <div class="relative">
      <!-- Header -->
      <Popover as="header" class="relative z-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between border-b border-gray-200 py-6 md:justify-start md:space-x-10">
            <div class="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span class="sr-only">Complaia Complaint Systems</span>
                <img class="h-8 w-auto sm:h-10" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia complaint system in web" />
              </a>
            </div>
            <div class="-my-2 -mr-2 md:hidden">
              <PopoverButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                <span class="sr-only">Open menu</span>
                <Bars3Icon class="h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
            <PopoverGroup as="nav" class="hidden space-x-10 md:flex">



            </PopoverGroup>
            <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <a href="/" class="cursor-pointer ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-600">Log in</a>
            </div>
          </div>
        </div>


      </Popover>

      <!-- Page Header -->
      <div class="relative mx-auto max-w-2xl py-24 px-4 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8">
        <div class="relative">
          <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">Handle <span class="text-purple-700">complaint processes</span> like a pro.</h1>
          <p class="mt-6 max-w-2xl text-xl text-gray-500">Choose an affordable plan that's suits your company needs.</p>
        </div>
      </div>
    </div>

    <main>
      <!-- Pricing Section -->
      <section class="relative" aria-labelledby="pricing-heading">
        <h2 id="pricing-heading" class="sr-only">Pricing</h2>

        <!-- Tiers -->
        <div class="mx-auto max-w-2xl space-y-12 px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:space-y-0 lg:px-8">
          <div v-for="tier in pricing.tiers" :key="tier.title" class="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
            <div class="flex-1">
              <h3 class="text-xl font-semibold text-gray-900">{{ tier.title }}</h3>
              <p v-if="tier.mostPopular" class="absolute top-0 -translate-y-1/2 transform rounded-full bg-purple-800 py-1.5 px-4 text-sm font-semibold text-white">Most popular</p>
              <p class="mt-4 flex items-baseline text-gray-900">
                <span class="text-5xl font-bold tracking-tight">${{ tier.price }}</span>
                <span class="ml-1 text-xl font-semibold">{{ tier.frequency }}</span>
              </p>
              <p class="mt-6 text-gray-500">{{ tier.description }}</p>

              <!-- Feature list -->
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="feature in tier.features" :key="feature" class="flex items-center">
                  <CheckIcon class="h-5 w-5 flex-shrink-0 text-purple-500" aria-hidden="true" />
                  <span class="ml-3 text-gray-500 text-xs font-medium">{{ feature }}</span>
                </li>
              </ul>
            </div>

            <a :href="tier.url" :class="[tier.mostPopular ? 'bg-purple-500 text-white hover:bg-purple-600' : 'bg-purple-50 text-purple-700 hover:bg-purple-100', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium']">{{ tier.cta }}</a>
          </div>
        </div>
      </section>



      <!-- FAQ -->
      <section aria-labelledby="faq-heading" class="mx-auto max-w-2xl divide-y divide-gray-200 py-24 px-4 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8">
        <h2 id="faq-heading" class="text-3xl font-bold tracking-tight text-gray-900">Frequently asked questions</h2>
        <div class="mt-8">
          <dl class="divide-y divide-gray-200">
            <div v-for="faq in faqs" :key="faq.id" class="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
              <dt class="text-base font-medium text-gray-900 md:col-span-5">{{ faq.question }}</dt>
              <dd class="mt-2 md:col-span-7 md:mt-0">
                <p class="text-base text-gray-500">{{ faq.answer }}</p>
              </dd>
            </div>
          </dl>
        </div>
      </section>
    </main>

    <!-- Footer -->
    <footer class="bg-gray-50" aria-labelledby="footer-heading">
      <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="mt-12 border-t border-gray-200 pt-8 flex justify-center">
          <p class="text-base text-gray-400 xl:text-center">&copy; 2022 Complaia Systems Sp. z o.o.  All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

import { CheckIcon } from '@heroicons/vue/outline';

onMounted(() => {
      if(window.dataLayer !== undefined)
      {
        if(window.dataLayer !== null)
        {

          window.dataLayer.push({
            event: "visitedAppSelectPlanPage",
            lang: window.navigator.userLanguage || window.navigator.language,
            system: window.navigator.userAgent,
            knownLanguages: window.navigator.languages,
            comingFrom: window.document.referrer
          });


          setTimeout(() => {
            window.dataLayer.push({
              event: "10secondOnSelectPlanPage",
              reached: true,
              info: "10 seconds"
            });
          },10000);

          setTimeout(() => {
            window.dataLayer.push({
              event: "30secondOnSelectPlanPage",
              reached: true,
              info: "30 seconds"
            });

          },30000);
        }
      }
})


const pricing = {
  tiers: [
    {
      title: 'Starter',
      price: 29.99,
      url: '/register?plan=ComplaiaStarter',
      frequency: '/month',
      description: 'Most often chosen by small companies.',
      features: [
        '1 user,',
        'Basic feed system',
        'Up to 2 images per complaint (4MB per file)',
        'Online complaint form & customer portal',
        'Customer chat',
        'Notification system',
        '6-month complaint history',
        '7 days free trial'
      ],
      cta: 'Select',
      mostPopular: false,
    },
    {
      title: 'Standard',
      price: 54.99,
      url: "/register?plan=ComplaiaStd",
      frequency: '/month',
      description: 'Most essential complaint tools for rapidly growing business',
      features: [
        'Up to 5 users',
        'User role system',
        'Advanced feed system',
        'Up to 4 images per complaint (6MB per file)',
        'PDF files support',
        'Online complaint form & customer portal',
        'Online form and customer portal customization',
        'Customer chat',
        'Notification system',
        'Analytics',
        '12-month complaint history',
        '7 days free trial'
      ],
      cta: 'Select',
      mostPopular: true,
    },
    {
      title: 'Pro',
      price: 99.99,
      url: '/register?plan=ComplaiaPro',
      frequency: '/month',
      description: 'The perfect plan for large companies with greater needs',
      features: [
        'Up to 10 users',
        'User role system',
        'Advanced feed system',
        'Up to 8 images per complaint (8MB per file)',
        'PDF files support',
        'Printable labels',
        'Online complaint form & customer portal',
        'Online form and customer portal customization',
        'Customer chat',
        'Notification system',
        'Analytics',
        '36-month complaint history',
        '7 days free trial'
      ],
      cta: 'Select',
      mostPopular: false,
    },
  ],
}
const faqs = [
  {
    id: 1,
    question: "What is Complaia?",
    answer:
      "Complaia is a set of tools for handling complaint processes and their automatization. Put a link to your portal in your webshop and Complaia will do the rest.",
  },
  {
    id: 2,
    question: "Why I should use Complaia?",
    answer:
      "Dealing with dissatisfied customers is stressful. Complaia allows you to resolve complaints efficiently. Have control in any, even the most difficult situations.",
  },
  {
    id: 3,
    question: "Can I test the application for free first?",
    answer:
      "You have 7 days of free access. Complaia allows you to unsubscribe at any time.",
  },
  {
    id: 4,
    question: "The pro plan is not enough for me. Can I ask for more?",
    answer:
      "For large companies, we offer special installations outside the service's main servers. You can also request additional functionality not available in regular plans. Contact our sales department: sales@complaia.com .",
  },
  // More questions...
]


</script>