<template>
  <div>
    <div class="sm:hidden mt-4">
      <Listbox as="div" v-model="currentComponentIndx">
        <!-- <ListboxLabel class="block text-sm font-medium text-gray-700"> Month </ListboxLabel> -->
        <div class="mt-1 relative">
          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
            <span class="block truncate">{{ tabs[currentComponentIndx].name }}</span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
              <ListboxOption @click="switchComponent(tab.componentToLoad)" as="template" v-for="(tab,tabNdx) in tabs" :key="tab.name" :value="tabNdx" v-slot="{ active, selected }">
                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tab.name }}</span>

                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <div v-for="tab in tabs" :key="tab.name" @click="switchComponent(tab.componentToLoad)" :class="[tab.current ? 'border-purple-500 text-purple-500' : 'border-transparent text-purple-400 hover:text-purple-400 hover:border-purple-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
            <component :is="tab.icon" :class="[tab.current ? 'text-purple-500' : 'text-purple-400 group-hover:text-purple-400', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
            <span>{{ tab.name }}</span>
          </div>
        </nav>
      </div>
    </div>
    <component :is="currentComponent" class="mt-6"></component>
  </div>
</template>
<script>
import { CreditCardIcon, UserIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import AccountSettings from '@/components/AccountSettings.vue';
import Billing from '@/components/Billing.vue';
import { shallowRef} from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
export default {
  name: "Profile",
  components: {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,SelectorIcon, CheckIcon},
  data: function () {
    return {
        currentComponent: shallowRef(AccountSettings),
        currentComponentIndx: 0,
        tabs: [
            { name: 'My Account', componentToLoad: AccountSettings, icon: UserIcon, current: true },
            { name: 'Billing', componentToLoad: Billing, icon: CreditCardIcon, current: false },
        ]
    }
  },
  mounted(){
    if(this.$route.query.billing === "true"){
      if(this.$store.state.userData.systemCreator === true){
        this.switchComponent(Billing)
      }
      this.$router.push('/dashboard/profile')
    }else{
      this.$router.push('/dashboard/profile')
    }
  },
  methods: {
    switchComponent(comp)
    {
      this.currentComponent = comp;
      for(let i=0; i<this.tabs.length;i++)
      {
        if(comp.name === this.tabs[i].componentToLoad.name)
        {
          this.tabs[i].current = true;
          this.currentComponentIndx = i
        }
        else
        {
          this.tabs[i].current = false;
        }
      }
    }
  },
};
</script>
<style scoped></style>
